import React from 'react';
import { string } from 'prop-types';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const OffshoreSthUnavailable = ({ stateName }) => {
  return (
    <div className="fulfillment-tile">
      <TileHead title="Delivery" automationId={IDS.LABEL.sthLabel} />
      <span className="fulfillment__primary_color fulfillment-msg" data-automation-id={IDS.UA.sth_unavailable}>
        Unavailable in {stateName}
      </span>
    </div>
  );
};

OffshoreSthUnavailable.propTypes = {
  stateName: string
};

OffshoreSthUnavailable.defaultProps = {
  stateName: ''
};

export { OffshoreSthUnavailable };
