import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { CheckAvailabilityContext } from '../CheckAvailabilityContext';
import { ZipInputModal } from './ZipInputModal';
import { InputForm } from './subcomponents/inputForm/InputForm';

export const ShippingService = () => {
  const {
    onSubmitForm,
    quantity,
    onError,
    storeId,
    itemId,
    price,
    zip,
    showCheckAvailability,
    setShowCheckAvailability,
    useDrawerModal
  } = useContext(CheckAvailabilityContext);

  const [getShipping, { data, loading, error }] = useDataModel('shipping', {
    variables: {
      itemId,
      storeId,
      zipCode: zip,
      quantity,
      price
    },
    fetchPolicy: 'no-cache'
  }, true);

  const onSubmit = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }
    getShipping();
  }, []);

  const [errorMessage, updateErrorMessage] = useState(null);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
    if (error) {
      let errorMessageToDisplay = error.message;
      if (error.message.search(/zipCode does not exist/i) > -1) {
        errorMessageToDisplay = 'Please enter a valid ZIP Code.';
      }
      updateErrorMessage(errorMessageToDisplay);
    } else if (data && onSubmitForm) {
      if (typeof window !== 'undefined' && window?.LIFE_CYCLE_EVENT_BUS?.lifeCycle) {
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('GLOBAL.DELIVERY_ZIP', {
          deliveryZip: zip
        });
      }
      onSubmitForm(data);
      updateErrorMessage(null);

    }
  }, [data, error, loading]);

  return (
    <div>
      {useDrawerModal
        && (
          <ZipInputModal
            errorMessage={errorMessage}
            onSubmit={onSubmit}
            overlay={showCheckAvailability}
            setOverlay={setShowCheckAvailability}
          />
        )}

      {!useDrawerModal
        && (
          <InputForm onSubmit={onSubmit} errorMessage={errorMessage} />
        )}
    </div>
  );
};
