import { useContext } from 'react';
import { CustomerContext } from '../components/CustomerContext';

export const useThdCustomer = () => {
  const { THDCustomer } = useContext(CustomerContext);

  const isServer = typeof window === 'undefined';

  if (isServer) {
    return {
      THDCustomer: null
    };
  }

  return THDCustomer;
};
