import React from 'react';

const ItemUnavailable = () => {
  return (
    <div className="fulfillment__unavailable" data-component="FulfillmentUnavailable">
      <div className="fulfillment__unavailable--content">This item is unavailable</div>
    </div>
  );
};

export { ItemUnavailable };
