import React from 'react';
import { string, bool, func, object } from 'prop-types';
import { CheckNearByStore } from './CheckNearByStore';
import { CHECK_NEARBY_STORES } from '../../../constants';
import './dominantTooltip.scss';

const BossDominantTooltip = ({
  isShipToHomeEligible,
  itemId,
  originalConfigId,
  onClick,
  storeName,
  addOnsAssemblyAndMore
}) => (
  <div className="BossSharedToolTip__wrapper">
    <div className="BossSharedToolTip__content">
      <div className="BossSharedToolTip__header" id="need-more-text-message">Need More?</div>
      <div className="BossSharedToolTip__body">
        <span>Just enter the quantity you need and we will ship it to</span>
        <span className="u__bold"> {storeName} </span>
        <span className="BossSharedToolTip__body">for free pickup.</span>
      </div>
      <div className="BossSharedToolTip__body">
        <span className="u__bold">{'Need it even sooner? '}</span>
        <CheckNearByStore
          itemId={itemId}
          originalConfigId={originalConfigId}
          addOnsAssemblyAndMore={addOnsAssemblyAndMore}
          isShipToHomeEligible={isShipToHomeEligible}
        >
          <span role="button" tabIndex={0} onClick={onClick}>{ CHECK_NEARBY_STORES }</span>
        </CheckNearByStore>
        <span>
          {' to see if another store has enough items in stock.'}
        </span>
      </div>
    </div>
  </div>
);

BossDominantTooltip.propTypes = {
  itemId: string.isRequired,
  originalConfigId: string,
  isShipToHomeEligible: bool,
  onClick: func,
  storeName: string,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: object
};
BossDominantTooltip.defaultProps = {
  isShipToHomeEligible: false,
  onClick: () => {},
  storeName: '',
  originalConfigId: null,
  addOnsAssemblyAndMore: null
};

export { BossDominantTooltip };
