import React from 'react';
import {
  number, string, func, bool, shape, arrayOf, object
} from 'prop-types';
import { DataProviderClient, QueryProvider } from '@thd-nucleus/data-sources';
import { Fulfillment } from '../components/Fulfillment';
import { transformModel } from './FulfillmentCartAdapter';

const FulfillmentTilesContainer = ({
  itemId,
  item,
  zipCode,
  storeId,
  quantity,
  handleCartFulfillmentClick,
  forDemo,
  isCartTiles,
  handleCartDeliveryZipChange,
  currentSelectedFulfillment,
  localizerStoreDetails,
  bopisCutOffBeforeStoreClose,
  getTodayDateTZ,
  getTomorrowDateTZ,
  cartAction,
  openApplianceCheckAvailability
}) => {

  const { directData, applianceDirectData } = transformModel(item);
  const fulfillments = item?.fulfillmentModel || [];

  return typeof window !== 'undefined' && (
    <div className="App" id={`fulfillment-tiles-${itemId}`} data-component="FulfillmentTilesContainer">
      <DataProviderClient
        debug
        link={null}
        federation
        headers={{ 'X-Experience-Name': 'general-merchandise' }}
        addTypename
      >
        <QueryProvider
          defaultVariables={{ storeId, zipCode }}
          cacheKey="FulfillmentOverview"
          skip={() => (true)}
        >
          <Fulfillment
            itemId={itemId}
            lineItemId={item.lineItemId}
            storeId={storeId}
            deliveryZip={zipCode}
            quantity={quantity}
            currentSelectedFulfillment={currentSelectedFulfillment}
            onChange={(resp) => {
              try {
                const clickedFulfillment = resp.method;
                if (clickedFulfillment && (clickedFulfillment !== currentSelectedFulfillment)) {
                  const clickedFulfillmentModel = fulfillments.find(
                    (ffm) => (ffm.fulfillmentMethod === clickedFulfillment)
                  );
                  handleCartFulfillmentClick(clickedFulfillmentModel);
                }
              } catch (error) {
                console.error(error);
              }
            }}
            onDeliveryZipCodeChange={handleCartDeliveryZipChange}
            isCartTiles={isCartTiles}
            directData={directData}
            applianceDirectData={applianceDirectData}
            forDemo={forDemo}
            localizerStoreDetails={localizerStoreDetails}
            bopisCutOffBeforeStoreClose={bopisCutOffBeforeStoreClose}
            getTodayDateTZ={getTodayDateTZ}
            getTomorrowDateTZ={getTomorrowDateTZ}
            cartAction={cartAction}
            leadTime={item?.configurationModel?.leadTime}
            openApplianceCheckAvailability={openApplianceCheckAvailability}
          />
        </QueryProvider>
      </DataProviderClient>
    </div>
  );
};

FulfillmentTilesContainer.propTypes = {
  itemId: string,
  item: shape({
    itemId: string.isRequired,
    fulfillmentModel: arrayOf(shape({
      fulfillmentMethod: string,
      selected: bool,
      freeShipping: bool,
      freeShippingIfThresholdMet: bool,
      pickUpStore: string,
      pickUpStoreAddress: string,
      inventoryStatus: string,
      availableQuantity: string,
      city: string,
      state: string,
      zipCode: string,
      enabled: bool,
      icon: string
    }))
  }),
  zipCode: string,
  storeId: string,
  quantity: number,
  handleCartFulfillmentClick: func,
  forDemo: bool,
  isCartTiles: bool,
  handleCartDeliveryZipChange: func,
  currentSelectedFulfillment: string,
  // eslint-disable-next-line react/forbid-prop-types
  localizerStoreDetails: object,
  bopisCutOffBeforeStoreClose: number,
  getTodayDateTZ: func,
  getTomorrowDateTZ: func,
  cartAction: string,
  openApplianceCheckAvailability: func
};

FulfillmentTilesContainer.defaultProps = {
  itemId: '',
  item: {
    fulfillmentModel: []
  },
  zipCode: '',
  storeId: '',
  quantity: 1,
  handleCartFulfillmentClick: () => { },
  forDemo: false,
  isCartTiles: false,
  handleCartDeliveryZipChange: () => { },
  currentSelectedFulfillment: '',
  localizerStoreDetails: {},
  bopisCutOffBeforeStoreClose: 3,
  getTodayDateTZ: () => { },
  getTomorrowDateTZ: () => { },
  cartAction: '',
  openApplianceCheckAvailability: () => { }
};

if (typeof window !== 'undefined') {
  window.FulfillmentTilesContainer = FulfillmentTilesContainer;
}

export { FulfillmentTilesContainer };
