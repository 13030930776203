import React from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import './fulfillmentPlaceholder.scss';

export const DeliveryMessageUnderTilePlaceholder = () => {
  return (
    <div className="fulfillment__undertile-placeholder--block">
      <div className="deliverymsg-undertile--divider-top" data-testid="deliverymsg-divider-top__placeholder" />
      <Placeholder type="rect" className="deliverymsg-undertile--delivery-express-truck-icon--padding" />
      <div className="fulfillment__undertile-placeholder--text-spacing">
        { new Array(2).fill('').map((value, i) => (
          <Placeholder
            type="text"
            className="fulfillment__deliverymsg-undertile--text-bars"
            key={`row-${i}`}
          />
        ))}
      </div>
    </div>
  );
};

DeliveryMessageUnderTilePlaceholder.propTypes = {
};

DeliveryMessageUnderTilePlaceholder.defaultProps = {
  isMajorAppliances: false
};
