import React from 'react';
import PropTypes from 'prop-types';
import { FULFILLMENT_METHODS } from '../../../constants';

const ShippingThreshold = ({
  type, hasFreeShipping, totalCharge, lineItemId,
  maxCartPriceContributor, consolidatedDeliveryCharge, selected, isCartTiles
}) => {
  const showMultiLineDeliveryFee = (isCartTiles && maxCartPriceContributor && selected);
  const getMultiLineDeliveryPrice = () => {
    return (
      <span className="u__bold u__text--success">Included
      </span>
    );
  };

  if (type === 'sth') {
    if (totalCharge === 0 || !totalCharge) {
      return (<span className="u__bold u__text--success">FREE</span>);
    }

    if (showMultiLineDeliveryFee) { return getMultiLineDeliveryPrice(); }

  }

  if (type === 'express delivery') {
    if (totalCharge === 0 || !totalCharge) {
      return <span className="u__bold u__text--success">FREE</span>;
    }
    if (showMultiLineDeliveryFee) { return getMultiLineDeliveryPrice(); }
  }

  if (type === FULFILLMENT_METHODS.APPLIANCE) {
    if (hasFreeShipping) {
      return <span className="u__bold u__text--success">FREE</span>;
    }
  }

  return null;
};

ShippingThreshold.propTypes = {
  type: PropTypes.string,
  hasFreeShipping: PropTypes.bool,
  totalCharge: PropTypes.number,
  lineItemId: PropTypes.string,
  maxCartPriceContributor: PropTypes.bool,
  consolidatedDeliveryCharge: PropTypes.string,
  selected: PropTypes.bool,
  isCartTiles: PropTypes.bool
};

ShippingThreshold.defaultProps = {
  type: null,
  hasFreeShipping: false,
  totalCharge: null,
  lineItemId: null,
  maxCartPriceContributor: false,
  consolidatedDeliveryCharge: null,
  selected: false,
  isCartTiles: false
};

export { ShippingThreshold };
