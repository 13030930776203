import React from 'react';
import {
  number, string, func, bool, object, arrayOf
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { dataModel } from './dataModel';
import './fulfillment.scss';
import { FulfillmentTiles } from './tiles/FulfillmentTiles';

const MajorApplianceFulfillment = (props) => {
  const {
    itemId,
    storeId,
    configurableApplianceItemIds,
    quantity,
    onChange,
    covidAlert,
    bopisMinimumThreshold,
    bopisMinimumThresholdStores,
    bopisHolidayTiming,
    directData,
    applianceDirectData
  } = props;

  return (
    <>
      <FulfillmentTiles
        itemId={itemId}
        storeId={storeId}
        configurableApplianceItemIds={configurableApplianceItemIds}
        covidAlert={covidAlert}
        quantity={quantity}
        onChange={onChange}
        thresholds={{
          bopisMinimumThreshold,
          bopisMinimumThresholdStores,
          bopisHolidayTiming
        }}
        directData={directData}
        applianceDirectData={applianceDirectData}
      />
    </>
  );
};

MajorApplianceFulfillment.displayName = 'MajorApplianceFulfillment';

MajorApplianceFulfillment.propTypes = {
  /** An Item that has product highlights */
  itemId: string.isRequired,
  configurableApplianceItemIds: arrayOf(string),
  covidAlert: bool,
  storeId: string,
  quantity: number,
  onChange: func,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string,
  bopisHolidayTiming: number,
  // eslint-disable-next-line react/forbid-prop-types
  directData: object,
  // eslint-disable-next-line react/forbid-prop-types
  applianceDirectData: object
};

MajorApplianceFulfillment.defaultProps = {
  configurableApplianceItemIds: null,
  covidAlert: false,
  quantity: 1,
  storeId: null,
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  onChange: () => { },
  bopisHolidayTiming: null,
  directData: null,
  applianceDirectData: null
};

// ['identifiers', 'info', 'pricing', 'availabilityType', 'fulfillment']
MajorApplianceFulfillment.dataModel = extend(
  CheckAvailability,
  dataModel
);

export { MajorApplianceFulfillment };
