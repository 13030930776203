import React from 'react';
import './discontinued.scss';

const Discontinued = () => {
  return (
    <div className="fulfillment__unavailable" data-component="FulfillmentDiscontinued">
      <div className="discontinued__wrapper">
        <div className="discontinued__two-tile-header">This Item is Discontinued</div>
        <div className="discontinued__content">We no longer carry this particular product.</div>
      </div>
    </div>
  );
};

export { Discontinued };
