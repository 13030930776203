import React from 'react';
import { string } from 'prop-types';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const OffshoreBossUnavailable = ({ stateName }) => {
  return (
    <div className="fulfillment-tile">
      <TileHead title="Pickup" automationId={IDS.LABEL.pickupLabel} />
      <span>Unavailable in {stateName}</span>
    </div>
  );
};
OffshoreBossUnavailable.propTypes = {
  stateName: string
};
OffshoreBossUnavailable.defaultProps = {
  stateName: null
};

export { OffshoreBossUnavailable };
