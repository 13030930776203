import React from 'react';
import PropTypes from 'prop-types';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { RedDangerAlert } from './RedDangerAlert';
import { isApplianceDeliveryUnavailable, isApplianceDeliveryError } from '../../helper/utils';
import { APPLIANCE_ERROR_MSG } from '../../constants';

export const ApplianceAlerts = ({
  applianceDeliveryData,
  storeObj
}) => {

  const isPickupUnavailable = !!storeObj?.unavailable;

  const { isExchangeCustomer } = useThdCustomer() || {};

  return (
    <>
      {isApplianceDeliveryError(applianceDeliveryData) && (
        <RedDangerAlert errorMessage={APPLIANCE_ERROR_MSG.GENERAL_ERROR} />
      )}
      {isPickupUnavailable && isApplianceDeliveryUnavailable(applianceDeliveryData) && !isExchangeCustomer && (
        <RedDangerAlert boldSorry errorMessage={APPLIANCE_ERROR_MSG.UNAVAILABLE_MSG} />
      )}
      {isPickupUnavailable && isApplianceDeliveryUnavailable(applianceDeliveryData) && isExchangeCustomer && (
        <RedDangerAlert boldSorry errorMessage={APPLIANCE_ERROR_MSG.EXCHANGE_UNAVAILABLE_MSG} />
      )}
    </>
  );
};

ApplianceAlerts.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  applianceDeliveryData: PropTypes.object,
  /* eslint-disable react/forbid-prop-types */
  storeObj: PropTypes.object
};

ApplianceAlerts.defaultProps = {
  applianceDeliveryData: null,
  storeObj: null
};
