import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@one-thd/sui-atomic-components';
import { CheckAvailabilityContext } from '../../../CheckAvailabilityContext';
import { determineZipCode } from '../../../helpers/utils';

export const InputForm = ({ onSubmit, errorMessage, placeholderText }) => {
  const {
    inputHeaderTitle,
    zip,
    setZip
  } = useContext(CheckAvailabilityContext);

  const inputElement = useRef(null);

  const [_zip, _setZip] = useState(determineZipCode(zip));

  const shouldDisableInput = !_zip;
  const [inputButtonDisabled, setInputButtonDisabled] = useState(shouldDisableInput);

  const onInputChange = (event) => {
    if (event?.target?.value?.length > 5) {
      return;
    }
    const numericalZip = event?.target?.value?.replace(/\D/, '');
    const isDisabled = numericalZip.length !== 5;
    if (!isDisabled) {
      event?.target?.setCustomValidity(''); // eslint-disable-line no-unused-expressions
    }
    _setZip(numericalZip);
    setInputButtonDisabled(isDisabled);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setZip(_zip);
    if (onSubmit) {
      onSubmit(event, _zip);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit} className="sui-grid sui-grid-cols-1 sui-gap-6">
        <TextField
          ref={inputElement}
          label={inputHeaderTitle}
          placeholder={placeholderText}
          type="number"
          id="check-availability__zip-code"
          onChange={onInputChange}
          value={_zip}
          status={errorMessage ? 'error' : 'default'}
          statusMessage={errorMessage}
          fullWidth
        />
        <Button
          type="submit"
          disabled={inputButtonDisabled}
          data-testid="check-avail-bttn"
          variant="primary"
          fullWidth
        >
          Update Zip Code
        </Button>
      </form>
    </>
  );
};

InputForm.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholderText: PropTypes.string
};

InputForm.defaultProps = {
  onSubmit: null,
  errorMessage: null,
  placeholderText: 'Tell us your ZIP Code'
};
