import React from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import {
  formatDate,
  formatInventory,
  showTodayOrTomorrow,
  showDateOrDateRange
} from '../../../helper/utils';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { IDS } from '../../../../automationTestIds';

const DefaultBoSS = ({
  fulfillment, backordered, fallbackMode, isSpecialOrder, configuratorHideQuantity
}) => {
  const bossLocation = (fulfillment?.locations || []).find((location) => location.type === 'online');
  const quantity = bossLocation?.inventory?.quantity;
  const formattedInventoryQty = formatInventory(quantity);
  const startDate = fulfillment?.deliveryDates?.startDate;
  const endDate = fulfillment?.deliveryDates?.endDate;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const deliveryEndDate = fulfillment?.deliveryDates?.endDate;
  const deliveryTimeline = fulfillment?.deliveryTimeline;
  return (
    <div className="fulfillment-tile">
      <TileHead title="Ship to Store" automationId={IDS.LABEL.stsLabel} />
      <div className="fulfillment__sub-title">
        {(!startDate && !endDate)
          && (
            <div className="u__bold pickup-timeline" data-automation-id={IDS.ETA.stsETA}>
            &nbsp;
            </div>
          )}
        {(startDate || endDate) && (
          formattedStartDate === formattedEndDate ? (
            <div className="u__bold pickup-timeline" data-automation-id={IDS.ETA.stsETA}>
              {showTodayOrTomorrow(deliveryEndDate, true, deliveryTimeline, formattedEndDate)}
            </div>
          ) : (
            <div className="u__bold pickup-timeline" data-automation-id={IDS.ETA.stsETA}>
              {showDateOrDateRange({ formattedStartDate, formattedEndDate })}
            </div>
          )
        )}
        {backordered ? (
          <div className="fulfillment-qty-row" data-automation-id={IDS.QTY.stsQty}>Backordered</div>
        ) : (((!fallbackMode && !fulfillment.isBlindsItem) && (!isSpecialOrder && !configuratorHideQuantity))
          && (
            <div className="fulfillment-qty-row" data-automation-id={IDS.QTY.stsQty}>
              <span className="u__text--success">{formattedInventoryQty}</span> available
            </div>
          )
        )}
      </div>
      <TileFooter className="u__bold">
        <span className="u__text--success">FREE</span>
      </TileFooter>
    </div>
  );
};

DefaultBoSS.propTypes = {
  backordered: bool,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  fallbackMode: bool,
  isSpecialOrder: bool,
  configuratorHideQuantity: bool
};

DefaultBoSS.defaultProps = {
  backordered: false,
  fallbackMode: false,
  isSpecialOrder: false,
  configuratorHideQuantity: false
};

export { DefaultBoSS };
