import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import '../fulfillmentTiles.scss';
import { setErrorEvents } from '../../../analytics';

export const NonMajorApplianceAlert = ({ showWarningBox }) => {
  const [isProReferralDisplayable, setIsProReferralDisplayable] = useState(false);
  // eslint-disable-next-line max-len
  const NON_MAJOR_APPLIANCE_TEXT = 'This item can be delivered but doesn\'t qualify for our regular Installation or Haul Away services.';
  const PRO_REFERRAL_TEXT = ' Add Pro Referral to get installation from a local professional.';

  useEffect(() => {
    setErrorEvents({ NON_MAJOR_APPLIANCE_TEXT });
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('product-add-ons.ready', ({ output }) => {
      const { isProReferralDisplayable: isProReferral = false } = output;
      setIsProReferralDisplayable(isProReferral);
    });
    return () => window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('product-add-ons.ready');
  }, []);

  return (
    <div className={`alert-inline alert-inline--warning inventory--warning${showWarningBox ? '' : '-tiles'}`}>
      <div>
        <span>
          {NON_MAJOR_APPLIANCE_TEXT} {isProReferralDisplayable ? PRO_REFERRAL_TEXT : ''}
        </span>
      </div>
    </div>
  );
};

NonMajorApplianceAlert.propTypes = {
  showWarningBox: bool
};
NonMajorApplianceAlert.defaultProps = {
  showWarningBox: false
};
