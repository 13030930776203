import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from '@one-thd/sui-atomic-components';
import { signIn } from '../../../../utils/utils';

export const SignInLink = ({ layoutType, customClasses, itemId, align }) => {
  const minimalLayout = classNames(
    'sui-font-regular sui-text-xs sui-leading-tight sui-underline sui-text-[#212120]',
    {
      'sui-align-right sui-text-right sui-float-right': align !== 'start',
      'sui-align-left sui-text-left sui-float-left': align === 'start'
    }
  );
  const detailedLayout = classNames(
    'sui-font-regular sui-text-base sui-leading-tight sui-align-left',
    'sui-w-full sui-text-left sui-underline sui-text-[#212120]'
  );
  const simpleLayout = classNames(
    'sui-font-regular sui-text-xs sui-leading-tight sui-text-[#212120] sui-align-left',
    'sui-w-full sui-text-left sui-underline'
  );
  let signInClasses;
  if (layoutType === 'minimal') {
    signInClasses = minimalLayout;
  } else if (layoutType === 'simple') {
    signInClasses = simpleLayout;
  } else if (layoutType === 'detailed') {
    signInClasses = detailedLayout;
  } else {
    signInClasses = customClasses;
  }

  return (
    <div className="sui-mb-1 sui-mt-1">
      <Link
        component="button" 
        onClick={() => signIn(itemId, layoutType)}
        className={signInClasses}>
        Sign in for The Home Depot price
      </Link>
    </div>
  );
};

SignInLink.propTypes = {
  layoutType: PropTypes.string,
  customClasses: PropTypes.string,
  itemId: PropTypes.string,
  align: PropTypes.string
};

SignInLink.defaultProps = {
  layoutType: '',
  customClasses: '',
  itemId: '',
  align: ''
};
