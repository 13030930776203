import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';

export const useCheckAvailability = (
  itemId,
  itemIds
) => {

  const idOrIds = itemId || (Array.isArray(itemIds) && !!itemIds.length ? itemIds.join() : '');
  const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: idOrIds ? '/mcc-cart/v3/appliance/deliveryAvailability/' : '' })
  });

  return useAxios('', { manual: true });
};