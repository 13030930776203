/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { getLocalStore } from '../helper/utils';

export const PickupTile = ({
  getWrapperClasses,
  fulfillmentObj,
  currentFulfillmentMethod,
  setSelectedFulfillmentMethod,
  isCartTiles,
  storeIdFromCart,
  lineItemId,
  originalConfigId
}) => {

  const localStoreLocation = getLocalStore(fulfillmentObj.subTemplate?.props?.fulfillment);
  const availableQuantity = localStoreLocation?.inventory?.quantity;
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? window.location.origin : 'https://www.homedepot.com';
  }
  const { storeId } = useStore();
  const { channel } = useContext(ExperienceContext);
  const {
    itemId,
    quantity,
    isShipToHomeEligible,
    addOnsAssemblyAndMore
  } = fulfillmentObj.subTemplate?.props || {};

  const cartReqParams = {
    itemId,
    quantity,
    host,
    keyword: isCartTiles ? storeIdFromCart : storeId,
    channel,
    isShipToHomeEligible,
    alterBrowserHistory: true,
    addOnsAssemblyAndMore
  };

  if (lineItemId) {
    cartReqParams.lineItemId = lineItemId;
  }

  if (originalConfigId) {
    cartReqParams.paintConfiguration = { configurationId: originalConfigId };
  }

  function pickupTileClickHandler(event) {
    if ((fulfillmentObj.method === currentFulfillmentMethod) || !fulfillmentObj.enabled) { return; }
    if (fulfillmentObj.isPickupNearby && fulfillmentObj.method === 'BOPIS') { return; }
    if ((Number(quantity) > availableQuantity) && !fulfillmentObj.isPickupNearby && fulfillmentObj.method === 'BOPIS') {
      event.stopPropagation();
      event.preventDefault();
      window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
        cartReqParams
      });
    } else {
      setSelectedFulfillmentMethod(fulfillmentObj.method);
    }
  }

  return (
    <>
      <button
        type="button"
        className={getWrapperClasses(fulfillmentObj)}
        onClick={pickupTileClickHandler}
      >
        {fulfillmentObj.template}
      </button>
    </>
  );
};

PickupTile.propTypes = {
  getWrapperClasses: PropTypes.func.isRequired,
  fulfillmentObj: PropTypes.object.isRequired,
  currentFulfillmentMethod: PropTypes.string.isRequired,
  setSelectedFulfillmentMethod: PropTypes.func.isRequired,
  isCartTiles: PropTypes.bool,
  storeIdFromCart: PropTypes.string,
  lineItemId: PropTypes.string,
  originalConfigId: PropTypes.string

};

PickupTile.defaultProps = {
  isCartTiles: false,
  storeIdFromCart: null,
  lineItemId: '',
  originalConfigId: null
};
