import React from 'react';
import PropTypes from 'prop-types';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { showTodayOrTomorrow, isMycartPage } from '../../../helper/utils';
import './detaCountDownTimer.scss';

const DetaCountDownTimer = ({
  dynamicEtaMinutes,
  dynamicEtaHours,
  showCountdownTime,
  inventoryQuantity,
  removeTimer,
  deliveryStartDate,
  isLongDayName,
  deliveryTimeline,
  startDate,
  zipCode
}) => {
  return dynamicEtaMinutes && showCountdownTime ? (
    <div
      className={'detaCountDownTimer' + (isMycartPage() ? ' detaCountDownTimer_cart' : '')}
      data-component="DetaCountDownTimer"
    >
      <span>Order within </span>
      <CountdownTimer
        hours={dynamicEtaHours}
        minutes={dynamicEtaMinutes}
        onRemove={removeTimer}
        showCondensedTimer
      />
      <span> to get it by </span>
      <span className="u__bold">
        {showTodayOrTomorrow(
          deliveryStartDate,
          isLongDayName,
          deliveryTimeline,
          startDate
        )}
      </span>
      {!inventoryQuantity && zipCode && (
        <>
          <span>&nbsp;to&nbsp;</span>
          <span className="u__bold">{zipCode}</span>
        </>
      )}
    </div>
  ) : null;
};

DetaCountDownTimer.propTypes = {
  dynamicEtaMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dynamicEtaHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCountdownTime: PropTypes.bool,
  inventoryQuantity: PropTypes.number,
  removeTimer: PropTypes.func,
  deliveryStartDate: PropTypes.string,
  isLongDayName: PropTypes.bool,
  deliveryTimeline: PropTypes.string,
  startDate: PropTypes.string,
  zipCode: PropTypes.string
};

DetaCountDownTimer.defaultProps = {
  dynamicEtaMinutes: null,
  dynamicEtaHours: '0',
  showCountdownTime: true,
  inventoryQuantity: null,
  removeTimer: {},
  deliveryStartDate: null,
  isLongDayName: true,
  deliveryTimeline: null,
  startDate: null,
  zipCode: null
};

export { DetaCountDownTimer };
