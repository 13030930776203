import React from 'react';
import PropTypes from 'prop-types';
import { track } from '../../analytics';
import { IDS } from '../../automationTestIds';

export const DeliveryHeader = ({
  itemId,
  zipCode,
  showCheckAvailability,
  setShowCheckAvailability,
  isCartTiles,
  lineItemId,
  fulfillmentMethod,
  applianceCountInCart,
  channel,
  isMajorAppliance,
  openApplianceCheckAvailability
}) => {

  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? 'https://www.homedepot.com'
      : window.location.origin;
  }

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
    track('click', {
      origin: 'delivery-options',
      eventName: 'check fulfillment availability',
      primaryCategory: 'button submission'
    });
  };

  const availabilityButton = (
    <button
      type="button"
      className="u__default-link"
      role="link"
      onClick={handleOnClick}
      data-testid="delivery-header-zipcode-link"
    >
      { zipCode }
    </button>
  );

  const cartReqParams = {
    itemId,
    lineItemId,
    zipCode,
    host,
    enableNucleusDrawer: true,
    fulfillmentMethod,
    digitalDataFlag: true
  };

  const openCheckAvailability = () => {
    if (window.location.href.includes('/mycart/home')) {
      openApplianceCheckAvailability({ lineItemId, shippingType: fulfillmentMethod, channel });
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('cart.check-availability', {
        cartReqParams
      });
    }
  };

  const appCheckAvailabilityButton = () => {
    return (
      <button
        type="button"
        className="u__default-link"
        role="link"
        onClick={openCheckAvailability}
      >
        { zipCode }
      </button>
    );
  };

  const applZipCode = (applianceCountInCart === 1) ? appCheckAvailabilityButton() : zipCode;

  return (
    <div className="fulfillment__header delivery__header" data-automation-id={IDS.LABEL.deliveryToLabel}>
      <span>
        Delivering to { isCartTiles && isMajorAppliance
          ? applZipCode : availabilityButton }
      </span>
    </div>
  );
};

DeliveryHeader.propTypes = {
  itemId: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired,
  isCartTiles: PropTypes.bool,
  lineItemId: PropTypes.string,
  fulfillmentMethod: PropTypes.string.isRequired,
  applianceCountInCart: PropTypes.number,
  channel: PropTypes.string.isRequired,
  isMajorAppliance: PropTypes.bool.isRequired,
  openApplianceCheckAvailability: PropTypes.func.isRequired
};

DeliveryHeader.defaultProps = {
  isCartTiles: false,
  lineItemId: '',
  applianceCountInCart: 0
};
