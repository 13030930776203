import React from 'react';
import PropTypes from 'prop-types';
import { BackorderedAlert } from '../../alerts/BackorderedAlert';

export const ApplianceShippingDetails = ({
  backordered
}) => {
  return (
    <>
      {backordered && <BackorderedAlert isMajorAppliance />}
    </>
  );
};

ApplianceShippingDetails.propTypes = {
  backordered: PropTypes.bool
};

ApplianceShippingDetails.defaultProps = {
  backordered: false
};
