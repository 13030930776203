/* eslint-disable max-len */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { getCurrentSelectedFulfillmentDetails, formatDate, showDateOrDateRange, showTodayOrTomorrow } from '../../helper/utils';

import { showBopisDate } from '../../helper/timeZoneUtils';

export const ListViewFulfillmentDisplay = ({ fulfillment, item }) => {
  const isAppliance = item?.product?.identifiers?.productType === 'Appliance';
  const isGiftCard = item?.product?.identifiers?.productType === 'GIFT_CARD';
  const {
    isPickup,
    shipToStore,
    startDate,
    endDate,
    deliveryTimeLine,
    storeHours,
    displayText,
    displayFulfillment,
    availableInventory,
    inventoryText,
    backorderedItem,
    location,
    deliveryStartDate,
    deliveryEndDate,
    estDeliveryDate,
    applDeliveryDate
  } = getCurrentSelectedFulfillmentDetails(fulfillment);

  const formattedStartDate = formatDate(startDate, false);
  const formattedEndDate = formatDate(endDate, false);
  const deliveryTimeline = deliveryTimeLine;
  const bossDateRange = shipToStore ? showDateOrDateRange({ formattedStartDate, formattedEndDate }) : '';
  const bossDateToday = shipToStore ? showTodayOrTomorrow(endDate, true, deliveryTimeline, formattedEndDate) : '';
  const bopisDate = (isPickup && !shipToStore) ? showBopisDate(storeHours) : '';
  const formattedDeliveryStartDate = deliveryStartDate ? formatDate(deliveryStartDate, false) : '';
  const formattedDeliveryEndDate = deliveryEndDate ? formatDate(deliveryEndDate, false) : '';
  const deliveryDate = (deliveryEndDate) ? showTodayOrTomorrow(deliveryEndDate, true, deliveryTimeline, formattedDeliveryEndDate) : '';

  return (
    <>
      {isAppliance ? (
        <>
          <Typography>
            <span className="u__bold">
              {displayFulfillment}
            </span>&nbsp;to&nbsp;{location} Earliest delivery date {applDeliveryDate} | Pickup Unavailable
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            <span className="u__bold">{displayFulfillment}</span>
            <span>
              {shipToStore && (formattedStartDate === formattedEndDate) ? (
                <>&nbsp;{bossDateToday}</>
              )
                : (
                  <>&nbsp;{bossDateRange}</>
                )}
              {isPickup && !shipToStore
                && (<>{bopisDate}</>)}
              {(!isPickup) && (formattedDeliveryStartDate === formattedDeliveryEndDate) ? (
                <>{deliveryDate}</>
              )
                : (
                  <>{estDeliveryDate}</>
                )}
              {displayText && (!isGiftCard) && (displayFulfillment || deliveryDate || estDeliveryDate) && (
              <>&nbsp;|&nbsp;<span className="sui-text-success">{displayText}</span></>)} 
            </span>
          </Typography>
          <Typography>
            {availableInventory && !backorderedItem && <>{inventoryText}</>}
            {backorderedItem && <>{backorderedItem}</>}
          </Typography>
        </>
      )}
    </>
  );
};

ListViewFulfillmentDisplay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fulfillment: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object
};

ListViewFulfillmentDisplay.defaultProps = {
  item: {},
  fulfillment: {}
};