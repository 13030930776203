import React from 'react';
import './excludedShipState.scss';
import PropTypes, { string } from 'prop-types';
import { CheckNearByStore } from '../partials/store/CheckNearByStore';
import { track } from '../../../analytics';

const ExcludedShipState = ({
  stateText,
  itemId,
  originalConfigId,
  checkAvailabilityComponent,
  showCheckAvailability,
  setShowCheckAvailability,
  addOnsAssemblyAndMore
}) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
    track('click', {
      origin: 'delivery-options',
      eventName: 'check fulfillment availability',
      primaryCategory: 'button submission'
    });
  };

  const availabilityButton = (
    <button
      type="button"
      className="u__default-link fulfillment__header_link line-height__initial font-weight__initial"
      role="link"
      onClick={handleOnClick}
    >
      ZIP Code
    </button>
  );

  return (
    <div className="fulfillment__unavailable" data-component="FulfillmentExcludedShipState">
      <div className="excludedShipState__wrapper">
        <div className="excludedShipState__two-tile-header">{`Not Available in ${stateText}`}</div>
        <div className="excludedShipState__content">
          {'Please '}
          <CheckNearByStore
            itemId={itemId}
            originalConfigId={originalConfigId}
            addOnsAssemblyAndMore={addOnsAssemblyAndMore}
          >
            change your store
          </CheckNearByStore>
          {' or '}
          {availabilityButton}
          {' to purchase'}
        </div>
      </div>
      {showCheckAvailability && (checkAvailabilityComponent)}
    </div>
  );
};

ExcludedShipState.propTypes = {
  stateText: string.isRequired,
  itemId: string.isRequired,
  originalConfigId: string,
  checkAvailabilityComponent: PropTypes.element.isRequired,
  showCheckAvailability: PropTypes.bool.isRequired,
  setShowCheckAvailability: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: PropTypes.object
};

ExcludedShipState.defaultProps = {
  addOnsAssemblyAndMore: null,
  originalConfigId: null
};

export { ExcludedShipState };
