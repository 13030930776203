import React from 'react';
import PropTypes from 'prop-types';
import '../fulfillmentTiles.scss';

export const RedDangerAlert = ({
  errorMessage,
  boldSorry
}) => {
  if (!errorMessage) { return; }

  const boldSorryMessage = boldSorry
    ? (<span className="u__bold">{'We\'re sorry. '}</span>)
    : null;

  // eslint-disable-next-line consistent-return
  return (
    <div className="fulfillment__fullcontent__danger">
      <div className="danger_text alert-inline alert-inline--danger">
        <span>{boldSorryMessage}{errorMessage}</span>
      </div>
    </div>
  );
};

RedDangerAlert.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  boldSorry: PropTypes.bool
};

RedDangerAlert.defaultProps = {
  boldSorry: false
};
