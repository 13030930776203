import {
  getFulfillmentTileModels,
  getMajorApplianceTileModels
} from '../components/tiles/FulfillmentTilesHelper';
import { getPreferredFfmFromAPI } from '../components/helper/utils';
import { FULFILLMENT_METHODS } from '../components/constants';

export const getFulfillmentInfoFromData = ({
  data,
  itemId,
  covidAlert,
  storeId,
  storeName,
  quantity,
  originalConfigId,
  zipCode,
  channel,
  thresholds,
  setShowCheckAvailability,
  currentDeliveryFfm,
  shippingData,
  preferSecondaryFFM,
  isMajorAppliance,
  applianceDeliveryData,
  configuratorFulfillment,
  isCartTiles,
  addOnsAssemblyAndMore,
  isSpecialOrder,
  configuratorHideQuantity,
  isConfigurableBlinds,
  disableTimer,
  disableSTH,
  disableBODFS,
  disableBOSS,
  disablePickupNearBy,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  isExchangeCustomer,
  hideShippingThreshold
}) => {
  const {
    fulfillment = {},
    availabilityType = {},
    identifiers = {},
    info = {},
    pricing = {}
  } = data?.product || {};
  const { fallbackMode = false } = fulfillment || {};
  const { original, value } = pricing || {};
  const productType = identifiers?.productType || '';
  const clearancePricing = data?.product?.pricing?.clearance;

  let fulfillmentModels = null;
  if (isMajorAppliance && data) {
    fulfillmentModels = getMajorApplianceTileModels({
      applianceDeliveryData,
      fulfillment,
      type: availabilityType?.type,
      covidAlert,
      quantity,
      itemId,
      storeName,
      channel,
      pricing,
      thresholds,
      productType,
      addOnsAssemblyAndMore,
      enableApplianceDeliveryCharge,
      enableFreeDeliveryForExchange,
      isExchangeCustomer,
      isCartTiles,
      hideShippingThreshold
    });
  } else if (data) {
    fulfillmentModels = getFulfillmentTileModels({
      fulfillment: configuratorFulfillment || fulfillment,
      type: availabilityType?.type,
      covidAlert,
      quantity,
      originalConfigId,
      zipCode,
      storeId,
      itemId,
      storeName,
      channel,
      pricing,
      thresholds,
      setShowCheckAvailability,
      currentDeliveryFfm,
      shippingData,
      subscriptionEligible: info?.hasSubscription || false,
      preferSecondaryFFM,
      productType,
      isCartTiles,
      addOnsAssemblyAndMore,
      fallbackMode,
      isSpecialOrder,
      configuratorHideQuantity,
      isConfigurableBlinds,
      disableTimer,
      disableSTH,
      disableBODFS,
      disableBOSS,
      disablePickupNearBy,
      clearancePricing,
      hideShippingThreshold
    });
  }

  let optimalFulfillmentFromAPI = getPreferredFfmFromAPI(fulfillment, false, isCartTiles, currentDeliveryFfm);
  // transform FFM received from response to usable one
  optimalFulfillmentFromAPI = optimalFulfillmentFromAPI === FULFILLMENT_METHODS.STH_FFM ? FULFILLMENT_METHODS.STH
    : FULFILLMENT_METHODS.BODFS;

  return {
    fulfillment,
    identifiers,
    info,
    original,
    value,
    pricing,
    availabilityType,
    fulfillmentModels,
    optimalFulfillmentFromAPI
  };
};
