import { getPickupTemplate } from './PickupTemplateGenerator';
import { getDeliveryTemplate } from './DeliveryTemplateGenerator';
import { getApplianceDeliveryTemplate } from './ApplianceDeliveryTemplateGenerator';

export const getFulfillmentTileModels = ({
  fulfillment,
  type,
  clearancePricing,
  covidAlert,
  quantity,
  originalConfigId,
  zipCode,
  storeId,
  itemId,
  storeName,
  channel,
  pricing,
  thresholds,
  setShowCheckAvailability,
  currentDeliveryFfm,
  shippingData,
  subscriptionEligible = false,
  preferSecondaryFFM = false,
  productType,
  isCartTiles,
  addOnsAssemblyAndMore,
  fallbackMode,
  isSpecialOrder,
  configuratorHideQuantity,
  isConfigurableBlinds,
  disableSTH,
  disableBODFS,
  disableBOSS,
  disablePickupNearBy,
  disableTimer,
  hideShippingThreshold
}) => {
  const shippingTemplate = getDeliveryTemplate({
    fulfillment,
    type,
    zipCode,
    storeId,
    quantity,
    itemId,
    setShowCheckAvailability,
    covidAlert,
    pricing,
    thresholds,
    currentDeliveryFfm,
    shippingData,
    subscriptionEligible,
    preferSecondaryFFM,
    isCartTiles,
    fallbackMode,
    isSpecialOrder,
    configuratorHideQuantity,
    isConfigurableBlinds,
    disableSTH,
    disableBODFS,
    disableTimer,
    storeName,
    hideShippingThreshold
  });

  const storeTemplate = getPickupTemplate({
    channel,
    itemId,
    covidAlert,
    fulfillment,
    type,
    quantity,
    originalConfigId,
    storeName,
    pricing,
    thresholds,
    productType,
    isCartTiles,
    addOnsAssemblyAndMore,
    fallbackMode,
    isSpecialOrder,
    configuratorHideQuantity,
    disableBOSS,
    disableSTH,
    disablePickupNearBy,
    clearancePricing
  });

  return [storeTemplate, shippingTemplate];

};

export const getMajorApplianceTileModels = ({
  applianceDeliveryData,
  fulfillment,
  type,
  covidAlert,
  quantity,
  itemId,
  storeName,
  channel,
  pricing,
  thresholds,
  productType,
  addOnsAssemblyAndMore,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  isExchangeCustomer,
  isCartTiles,
  hideShippingThreshold
}) => {

  const shippingTemplate = getApplianceDeliveryTemplate({
    applianceDeliveryData,
    pricing,
    enableApplianceDeliveryCharge,
    enableFreeDeliveryForExchange,
    isExchangeCustomer,
    isCartTiles,
    hideShippingThreshold
  });

  const storeTemplate = getPickupTemplate({
    channel,
    itemId,
    covidAlert,
    fulfillment,
    type,
    quantity,
    storeName,
    pricing,
    thresholds,
    productType,
    addOnsAssemblyAndMore
  });

  return [storeTemplate, shippingTemplate];

};
