import React from 'react';
import './storeOnlyTooltip.scss';

const StoreOnlyInfoToolTip = () => (
  <div className="StoreOnlyTooltip__wrapper">
    <span className="StoreOnlyTooltip__header">Not Eligible for Order Pickup</span>
    <span>
      <span className="StoreOnlyTooltip__body">
        While this item is in stock at a store near you, it is not eligible for Order Pickup. Visit
        your local Home Depot to purchase in store.&nbsp;
      </span>
    </span>
  </div>
);

export { StoreOnlyInfoToolTip };
