import { useEffect, useContext } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { processApplianceFulfillmentResponse } from '@thd-olt-component-react/check-availability';
import { setErrorEvents } from '../analytics';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';

export const useFulfillmentGetData = ({
  itemId,
  storeId,
  zipCode,
  directData = null,
  quantity = 1,
  bypassQuantitySkip
}) => {
  let queryVariables = {
    itemId,
    storeId,
    zipCode
  };
  if (bypassQuantitySkip || quantity > 1) {
    queryVariables = {
      ...queryVariables,
      quantity
    };
  }

  const { exchangeCustomerCookies } = useContext(CustomerContext);
  const { isExchangeCustomer } = useThdCustomer() || {};

  const productOptions = {
    variables: queryVariables,
    ssr: false,
    skip: !!directData
  };

  if (isExchangeCustomer) {
    productOptions.context = { headers: exchangeCustomerCookies };
  }

  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptions);

  return { data: data || { product: directData }, loading, error };
};

export const useShippingGetData = ({
  itemId,
  storeId,
  zipCode,
  quantity,
  value,
  isSthAvailable,
  directData = null,
  configId,
  isMajorAppliance = false
}) => {
  const shippingQueryProps = {
    variables: {
      itemId,
      storeId,
      zipCode,
      quantity,
      configId,
      price: parseInt((value * quantity).toFixed(2), 10)
    },
    skip: !!directData || !isSthAvailable || isMajorAppliance // don't call shipping GraphQL for these scenarios
  };
  const { data, loading, error } = useDataModel('shipping', shippingQueryProps);

  return { shippingData: data?.shipping, shippingLoading: loading, shippingError: error };
};

export const useApplianceGetData = ({
  itemId,
  storeId,
  itemIds = [],
  zipCode,
  isMajorAppliance = false,
  applianceDirectData = null,
  setApplianceDeliveryData
}) => {
  const checkAvailabilityOptions = {
    ssr: false,
    variables: {
      itemIds: itemId ? [itemId] : itemIds,
      zipCode,
      localStoreId: storeId
    },
    skip: applianceDirectData || !isMajorAppliance
  };

  const { data: checkAvailabilityData, error: checkAvailabilityError } = useDataModel(
    'checkAvailability',
    checkAvailabilityOptions
  );

  // For setting direct appliance delivery data (for mocking and mycart)
  useEffect(() => {
    let data = null;
    if (applianceDirectData) {
      if (applianceDirectData?.DeliveryAvailabilityResponse?.errorData) {
        data = applianceDirectData?.DeliveryAvailabilityResponse?.errorData || null;

        if (data?.errors?.error) {
          const error = data?.errors?.error;
          const errorModel = {
            message: error.description || ''
          };

          if (error.errorCode) {
            errorModel.code = error.errorCode;
          }

          setErrorEvents(errorModel);
        }
      } else {
        data = applianceDirectData?.DeliveryAvailabilityResponse?.deliveryAvailability || null;
      }
      setApplianceDeliveryData(data);
    }
  }, [applianceDirectData]);

  useEffect(() => {
    if (!isMajorAppliance || applianceDirectData) return;

    const getDeliveryAvailability = () => {
      let applianceDeliveryData = null;

      if (checkAvailabilityError) {
        applianceDeliveryData = {
          errors: checkAvailabilityError
        };
      } else {
        const fulfillmentOptions = checkAvailabilityData?.checkAvailability?.fulfillmentOptions;
        const services = fulfillmentOptions?.[0]?.services;
        const locations = services?.[0]?.locations;

        let earliestAvailabilityDate = null;
        if (services?.[0]?.earliestAvailabilityDate) {
          earliestAvailabilityDate = new Date(services?.[0]?.earliestAvailabilityDate + ' ' + new Date().getFullYear());
        }

        const availabilityData = {
          DeliveryAvailabilityResponse: {
            deliveryAvailability: {
              zipCode,
              primaryStrNbr: locations?.[0]?.locationId,
              availability: services?.[0]?.deliveryAvailabilityDetail,
              earliestAvailabilityDate
            }
          }
        };
        applianceDeliveryData = availabilityData.DeliveryAvailabilityResponse.deliveryAvailability;
        try {
          const processedData = processApplianceFulfillmentResponse(availabilityData);
          const eventData = {
            processedData,
            zipCode: processedData.zipCode || zipCode,
            autoCheck: !!applianceDeliveryData && !applianceDirectData,
            stringifiedQueryId: itemIds?.join(',') || itemId || ''
          };
          LIFE_CYCLE_EVENT_BUS.trigger('check-availability.depot-direct-success', eventData);
          LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
            'check-availability.depot-direct-success',
            eventData
          );
        } catch (err) {
          console.log(err);
        }
      }
      setApplianceDeliveryData(applianceDeliveryData);
    };

    getDeliveryAvailability();
  }, [checkAvailabilityData, isMajorAppliance, checkAvailabilityError]);
};
