/* global cookieUtils  */
import { formatDate } from '@thd-olt-functional/utils';
import {
  AVAILABLE,
  BACK_ORDERED,
  OOS_ETA_UNAVAILABLE,
  UNAVAILABLE,
  MAJOR_APPLIANCE,
  ERROR,
  DELIVERY_ZIP
} from './constants';

export const isDeliveryResponseModel = (result) => {
  const hasValidStatus = result?.status?.code === '0001';
  const hasItems = result?.items && result.items.length > 0;
  const hasDeliveries = hasItems && result.items[0]?.deliveries && result.items[0].deliveries.length > 0;

  return hasValidStatus && hasDeliveries;
};

export const validateZipCode = (zip) => {
  return (zip && zip.length === 5 && /^\d{5}$/.test(zip));
};

export const determineItemOrItemTypes = (productData) => {
  return productData?.products?.every((item) => item.identifiers?.productType === MAJOR_APPLIANCE);
};

export const setDeliveryZipCookie = (zipCode) => {
  if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer) {
    // eslint-disable-next-line no-undef
    THD_LOCALIZER_AUTO_INIT.Localizer.updateDeliveryZipcodeDetails(zipCode, 'USER');
  }
};

export const determineZipCode = (zipCode) => {
  if (zipCode) {
    return zipCode;
  }

  return !zipCode && typeof window !== 'undefined' && window.cookieUtils?.readBrowserCookie?.(DELIVERY_ZIP)
    ? window.cookieUtils?.readBrowserCookie?.(DELIVERY_ZIP) : '';
};

const getLatestDeliveryDate = (deliveryInfo) => {
  if (!deliveryInfo || !deliveryInfo.length) {
    return null;
  }
  const eligibleSlots = deliveryInfo.filter((delivery) => delivery.slotsAvailable > 0);
  return eligibleSlots[eligibleSlots.length - 1]?.date;
};

const reformatDate = (date, correctOffset = false) => {
  if (!date) {
    return null;
  }

  return formatDate({ date, shortMonth: true, dayMonthDateFormat: true, correctOffset });
};

const reformatDateFromString = (date) => {
  if (!date) {
    return null;
  }
  const dateArray = date.split('-');
  const year = dateArray[0];
  const month = parseInt(dateArray[1], 10) - 1;
  const day = dateArray[2];

  const newDate = new Date(year, month, day);
  return formatDate({ date: newDate, shortMonth: true, dayMonthDateFormat: true, correctOffset: false });
};

export const processResponse = (data) => {
  const { deliveryAvailability: details, errorData } = data?.DeliveryAvailabilityResponse;
  const hasOutOfStock = details?.availability?.some((item) => item.status.includes('OOS_'));
  const hasBackordered = details?.availability?.some((item) => item.status === BACK_ORDERED);
  const isUnavailable = details?.availability?.some((item) => item.status === UNAVAILABLE);
  let availabilityDate = details?.earliestAvailabilityDate;
  let defaultMessagingStatusToUse = '';
  let changeAvailableDateMessage = false;
  let formattedEarliestAvailabilityDate;

  if (errorData) {
    defaultMessagingStatusToUse = ERROR;
  } else if (isUnavailable) {
    defaultMessagingStatusToUse = UNAVAILABLE;
  } else if (hasOutOfStock) {
    defaultMessagingStatusToUse = OOS_ETA_UNAVAILABLE;
  } else if (hasBackordered) {
    defaultMessagingStatusToUse = BACK_ORDERED;
  } else {
    defaultMessagingStatusToUse = AVAILABLE;
    if (!availabilityDate) {
      const newDate = new Date(Date.now());
      newDate.setDate(newDate.getDate() + 30);
      availabilityDate = newDate;

      changeAvailableDateMessage = true;
    }
  }

  if (typeof availabilityDate === 'string') {
    formattedEarliestAvailabilityDate = reformatDateFromString(availabilityDate);
  } else {
    formattedEarliestAvailabilityDate = reformatDate(availabilityDate);
  }

  return {
    zipCode: details?.zipCode || '',
    hasBackordered,
    hasOutOfStock,
    isUnavailable,
    defaultMessagingStatusToUse,
    earliestAvailabilityDate: formattedEarliestAvailabilityDate,
    changeAvailableDateMessage,
    rawEarliestAvailabilityDate: availabilityDate,
    products: details?.availability?.map((item) => {
      return {
        itemId: item.itemId,
        rawBackOrderedEstimatedDeliveryDate: item.etaDate,
        backOrderedEstimatedDeliveryDate: item.etaDate === null ? '' : reformatDate(item.etaDate),
        deliveryDate: formattedEarliestAvailabilityDate,
        rawDeliveryDate: availabilityDate,
        primaryStoreNumber: details?.primaryStrNbr,
        endDeliveryDate: reformatDate(getLatestDeliveryDate(details?.deliveryInfo)),
        status: item.status
      };
    })
  };
};
