import React from 'react';
import { Rebates } from '../component/partials/Rebates';
import { LAYOUT_TYPES } from '../utils/utils';

export const PRODUCT_DOMAINS = ['identifiers', 'media', 'pricing', 'info'];

export const PRICE_TYPES = {
  STANDARD: 'STANDARD',
  BASIC: 'BASIC',
  STRIKE_THROUGH: 'STRIKE_THROUGH',
  STARTING_AT: 'STARTING_AT',
  MAP_PRICE: 'MAP_PRICE',
  ECO_REBATE: 'ECO_REBATE',
  INSTANT_REBATE: 'INSTANT_REBATE',
  BULK_PRICE: 'BULK_PRICE',
  UNIT_AND_CASE: 'UNIT_AND_CASE',
  LIMIT_PER_ORDER: 'LIMIT_PER_ORDER',
  RANGE_PRICE: 'RANGE_PRICE',
  BRIO: 'BRIO',
  LIVE_GOODS: 'LIVE_GOODS',
  NO_DETAILS: 'NO_DETAILS'
};

export const EXCHANGE_PROMOTION_TAG = 'EXCHANGE';
export const CLEARANCE_MESSAGE = 'See In-Store Clearance Price';

const helpers = {
  getPricingObject: ({
    skuModel, displayEachUom, omitDetails, basic, disableRangePricing = false, useNewMapPolicy
  }) => {
    const { pricing } = skuModel;
    const ret = {};
    let priceType = PRICE_TYPES.STANDARD;

    if (!pricing) {
      return { _price: ret, type: '' };
    }

    const {
      alternatePriceDisplay,
      alternatePricingDisplay,
      unitWasPrice,
      unitNowPrice,
      unitOfMeasureCoverage,
      pricingMessage,
      specialBuyPrice,
      nowPrice,
      wasPrice,
      minPriceRange,
      maxPriceRange,
      wasMinPriceRange,
      wasMaxPriceRange,
      percentageOff,
      dollarOff,
      installationServiceType,
      productSubType,
      savingsCenter,
      hidePrice,
      bulkPrice,
      bulkPriceThresholdQty,
      caseUnitUom,
      uom,
      rebates,
      storePromotion,
      quantityLimit,
      mapAboveOriginalPrice,
      mapDetail
    } = pricing;

    //fields we get from the new mapDetails node to apply new map policy logic
    const {
      percentageOff: mapPercentageOff,
      dollarOff: mapDollarOff,
      mapPolicy,
    } = mapDetail || {};

    if (omitDetails) {
      if (pricingMessage) {
        if (mapAboveOriginalPrice && mapPolicy !== 'showSpecialPriceIfAuth') {
          ret.mapPolicy = mapPolicy;
          return { _price: ret, type: PRICE_TYPES.NO_DETAILS };
        }

      } else if (alternatePriceDisplay || alternatePricingDisplay) {
        if (uom === 'sq. ft.') {
          if (unitWasPrice !== unitNowPrice) {
            ret.price = helpers.toFixed(unitNowPrice, 2);
          } else {
            ret.price = helpers.toFixed(unitWasPrice, 2);
          }
        } else if (uom !== 'sq. ft.') {
          if (wasPrice !== nowPrice) {
            ret.price = helpers.toFixed(nowPrice, 2);
          } else {
            ret.price = helpers.toFixed(wasPrice, 2);
          }
        }
      } else if (percentageOff) {
        ret.save = helpers.savingsPrice(
          percentageOff, dollarOff,
          wasPrice, specialBuyPrice, nowPrice
        );
        ret.price = helpers.toFixed(nowPrice, 2);
        ret.pricingValues = {
          wasPrice: helpers.toFixed(wasPrice, 2),
          nowPrice: ret.price
        };
      } else if (!pricingMessage && !specialBuyPrice) {
        ret.price = helpers.toFixed(nowPrice, 2);
      }
      ret.uom = uom ? uom.toLowerCase() : '';
      ret.priceBadge = helpers.priceBadge(savingsCenter);
      ret.mapPolicy = mapPolicy;
      return { _price: ret, type: PRICE_TYPES.NO_DETAILS };
    }

    // Basic price
    if (basic) {
      if (pricingMessage) {
        if (mapAboveOriginalPrice) {
          return {
            _price: ret,
            type: PRICE_TYPES.NO_DETAILS
          };
        }
      } else {
        ret.price = helpers.toFixed(nowPrice, 2);
        return {
          _price: ret,
          type: PRICE_TYPES.BASIC
        };
      }
    }

    // Range Price
    if (!disableRangePricing && pricing && minPriceRange && maxPriceRange
      && minPriceRange !== maxPriceRange) {
      const minPrice = parseFloat(minPriceRange);
      const maxPrice = parseFloat(maxPriceRange);
      ret.minPrice = helpers.toFixed(minPriceRange, 2);
      ret.maxPrice = helpers.toFixed(maxPriceRange, 2);
      // strike-through range
      if (wasMinPriceRange && wasMaxPriceRange) {
        const wasMin = parseFloat(wasMinPriceRange);
        const wasMax = parseFloat(wasMaxPriceRange);
        if (wasMin > minPrice && wasMax > maxPrice) {
          if (wasMin !== wasMax) {
            ret.wasMinPrice = helpers.toFixed(wasMinPriceRange, 2);
            ret.wasMaxPrice = helpers.toFixed(wasMaxPriceRange, 2);
          } else {
            ret.wasMinPrice = helpers.toFixed(wasMinPriceRange, 2);
          }
        }
      }
      // savings %
      if (percentageOff && percentageOff > 0) {
        ret.percentageOff = helpers.toFixed(percentageOff);
      }
      return { _price: ret, type: PRICE_TYPES.RANGE_PRICE };
    }

    // BRIO SKU
    if (pricing
      && ((installationServiceType && installationServiceType === 'SFI')
        || (productSubType && productSubType.name === 'SFI'))
    ) {
      ret.isBRIOSku = true;
      return { _price: ret, type: PRICE_TYPES.BRIO };
    }

    if (hidePrice) {
      return { _price: {}, type: PRICE_TYPES.LIVE_GOODS };
    }

    // Price Badges
    ret.priceBadge = helpers.priceBadge(savingsCenter);

    // Bulk pricing
    if (bulkPrice && bulkPriceThresholdQty) {
      ret.bulkPrice = bulkPrice;
      ret.bulkPriceThresholdQty = bulkPriceThresholdQty;
      ret.priceBadge = 'Bulk-Price';
      priceType = PRICE_TYPES.BULK_PRICE;
    }

    // Save
    ret.save = helpers.savingsPrice(
      percentageOff, dollarOff,
      wasPrice, specialBuyPrice, nowPrice
    );



    // Starting at price
    if (pricingMessage && pricingMessage === 'Starting at') {
      ret.pricingMessage = pricingMessage;
      if (wasPrice) {
        ret.price = helpers.toFixed(wasPrice, 2);
      }
      if (nowPrice) {
        ret.price = helpers.toFixed(nowPrice, 2);
      }
      if (wasPrice && nowPrice && nowPrice !== wasPrice) {
        ret.pricingValues = {
          wasPrice: helpers.toFixed(wasPrice, 2),
          nowPrice: helpers.toFixed(nowPrice, 2)
        };
      }

      ret.uom = uom ? uom.toLowerCase() : '';
      return { _price: ret, type: PRICE_TYPES.STARTING_AT };
    }

    // MAP pricing
    const mapViolationApplies = mapDetail && mapPolicy;
    const loginToSeePricePolicy = (mapPolicy === 'showSpecialPriceIfAuth');
    const seePriceInCartPolicy = (mapPolicy === 'showPriceInCart');

    if (useNewMapPolicy) {
      if (mapViolationApplies) {
        let mapPricingMessage = pricingMessage;
        if (wasPrice && nowPrice && loginToSeePricePolicy) {
          ret.save = helpers.savingsPrice(
            (percentageOff || mapPercentageOff),
            (dollarOff || mapDollarOff),
            wasPrice,
            specialBuyPrice,
            nowPrice
          );
        } else {
          ret.save = {};
        }
        if (seePriceInCartPolicy) {
          ret.hasStrikeThrough = true;
          mapPricingMessage = 'See Low Price in Cart';
          if (rebates && rebates.hasEcoRebates) {
            ret.hasEcoRebates = rebates.hasEcoRebates;
          }
        }
        ret.pricingValues = {
          wasPrice: wasPrice ? helpers.toFixed(wasPrice, 2) : '',
          nowPrice: nowPrice ? helpers.toFixed(nowPrice, 2) : ''
        };
        ret.pricingMessage = mapPricingMessage;
        ret.mapPolicy = mapPolicy;
        return {_price: ret, type: PRICE_TYPES.MAP_PRICE};
      }
    } else {
      if (pricingMessage) {
        if (mapAboveOriginalPrice) {
          ret.pricingMessage = 'See Low Price in Cart';
          priceType = PRICE_TYPES.MAP_PRICE;
        } else if (typeof mapAboveOriginalPrice !== 'undefined') {
          if (wasPrice && nowPrice !== wasPrice) {
            ret.pricingValues = {
              wasPrice: helpers.toFixed(wasPrice, 2),
              nowPrice: ''
            };
            ret.pricingMessage = 'See Lower Price in Cart';
          } else {
            ret.pricingMessage = 'See Low Price in Cart';
          }
          if (rebates && rebates.hasEcoRebates) {
            ret.hasEcoRebates = rebates.hasEcoRebates;
          }
          ret.mapPolicy = mapPolicy;
          return { _price: ret, type: PRICE_TYPES.MAP_PRICE };
        }
      } else if (specialBuyPrice
        && wasPrice >= specialBuyPrice && specialBuyPrice > nowPrice) {
        ret.mapPrice = {
          wasPrice: helpers.toFixed(wasPrice),
          pricingMessage: 'See Lower Price in Cart',
          hasStrikeThrough: true
        };
        if (wasPrice && nowPrice && nowPrice !== wasPrice) {
          ret.pricingValues = {
            wasPrice: helpers.toFixed(wasPrice, 2),
            nowPrice: ''
          };
        }
        ret.pricingMessage = 'See Lower Price in Cart';
        priceType = PRICE_TYPES.MAP_PRICE;
      } else if (mapAboveOriginalPrice) {
        ret.pricingMessage = 'See Low Price in Cart';
        priceType = PRICE_TYPES.MAP_PRICE;
      }
    }

    // Unit pricing/Alternate pricing
    const mapViolation = !pricingMessage && !mapAboveOriginalPrice;
    const hasAlternatePrice = !!alternatePriceDisplay || !!alternatePricingDisplay;
    const hasQuantityLimit = !!quantityLimit;
    const hasUnitOfMeasureCoverage = !!unitOfMeasureCoverage;
    const bulbProductScenario = caseUnitUom === 'bulb' && hasUnitOfMeasureCoverage;
    const bulbProductCheck = bulbProductScenario && !hasQuantityLimit;
    if (mapViolation && (hasAlternatePrice || bulbProductCheck)) {
      if (unitWasPrice && unitNowPrice && unitWasPrice !== unitNowPrice) {
        ret.unitPricingValues = {
          wasPrice: helpers.toFixed(unitWasPrice, 2),
          nowPrice: helpers.toFixed(unitNowPrice, 2)
        };
      }
      if (wasPrice && nowPrice && nowPrice !== wasPrice) {
        ret.pricingValues = {
          wasPrice: helpers.toFixed(wasPrice, 2),
          nowPrice: helpers.toFixed(nowPrice, 2)
        };
      }

      ret.price = helpers.toFixed(unitNowPrice, 2);

      if (caseUnitUom) {
        ret.uom = caseUnitUom.toLowerCase();
      }
      if (percentageOff > 0) {
        ret.save = {
          dollarOff: helpers.toFixed(dollarOff, 2),
          percentageOff: helpers.toFixed(percentageOff)
        };
      }
      if (uom) {
        ret.alternatePrice = nowPrice;
        ret.alternateUom = uom.toLowerCase();
      }
      if (rebates && rebates.hasEcoRebates) {
        ret.hasEcoRebates = rebates.hasEcoRebates;
      }
      return { _price: ret, type: PRICE_TYPES.UNIT_AND_CASE };
    }

    // was pricing
    if (nowPrice && wasPrice && nowPrice !== wasPrice) {
      ret.pricingValues = {
        wasPrice: helpers.toFixed(wasPrice, 2),
        nowPrice: helpers.toFixed(nowPrice, 2)
      };
      if (priceType === PRICE_TYPES.STANDARD) {
        priceType = PRICE_TYPES.STRIKE_THROUGH;
      }
    }

    ret.price = '';
    if (wasPrice) {
      ret.price = helpers.toFixed(wasPrice, 2);
    } else if (nowPrice) {
      ret.price = helpers.toFixed(nowPrice, 2);
    }

    if (displayEachUom && uom) {
      ret.uom = uom.toLowerCase();
    } else if (uom && uom !== 'each') {
      ret.uom = uom.toLowerCase();
    }

    // Final Price Type Logic
    if (rebates && rebates.hasEcoRebates) {
      ret.hasEcoRebates = rebates.hasEcoRebates;
      priceType = PRICE_TYPES.ECO_REBATE;
    }
    if (storePromotion && storePromotion.longDescription) {
      priceType = PRICE_TYPES.INSTANT_REBATE;
    }
    if (hasQuantityLimit) {
      priceType = PRICE_TYPES.LIMIT_PER_ORDER;
    }
    ret.mapPolicy = mapPolicy;
    return { _price: ret, type: priceType };
  },
  toFixed: (price, n) => {
    if (!price) {
      return '0';
    }
    if (typeof price === 'string') {
      return parseFloat(price).toFixed(n);
    }
    return price.toFixed(n);
  },
  parsePriceValue: (price) => {
    let ret = {};
    if (!price) {
      return '';
    }
    const priceSplit = price.toString().split('.');
    ret.dollars = priceSplit[0]; // eslint-disable-line
    ret.cents = priceSplit[1] || '00';
    if (ret.cents.length === 1) {
      ret.cents += '0';
    }
    return ret;
  },
  parseUom: (uom, isDisplayEachUom) => {
    let parsedUom = `/${uom}`;
    if (uom === 'each') {
      if (isDisplayEachUom) {
        parsedUom = '/each';
      } else {
        parsedUom = '';
      }
    } else if (uom.indexOf('pallet(') > -1) {
      parsedUom = '/pallet';
    }
    return parsedUom;
  },
  handlePriceParsing: (priceToParse, skipFormat) => {
    const retPrice = helpers.parsePriceValue(priceToParse);
    if (!retPrice) return null;

    if (retPrice.dollars === '0' && !skipFormat) {
      return (<span>{retPrice.cents}<span>¢</span></span>);
    }
    if (retPrice.dollars === '0' && skipFormat) {
      return (<span>{retPrice.cents}¢</span>);
    }
    return (<span>{`$${retPrice.dollars}.${retPrice.cents}`}</span>);
  },
  triggerAnalytics: (tooltipLocation) => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('price.click', { location: tooltipLocation });
    }
  },
  savingsPrice: (percentageOff, dollarOff, wasPrice, specialBuyPrice, nowPrice) => {
    // Save
    let ret = {};
    const isOnSale = !(wasPrice >= specialBuyPrice && specialBuyPrice > nowPrice);
    if (percentageOff > 0 && isOnSale) {
      ret.save = {
        dollarOff: helpers.toFixed(dollarOff, 2),
        percentageOff: helpers.toFixed(percentageOff)
      };
    }
    return ret.save;
  },
  priceBadge: (savingsCenter) => {
    // Price Badges
    let ret = {};
    const badge = savingsCenter && savingsCenter.toLowerCase();
    if (badge === 'overstock') {
      ret.priceBadge = 'Over-Stock';
    } else if (badge === 'special buys') {
      ret.priceBadge = 'Special-Buy';
    } else if (badge === 'new lower prices') {
      ret.priceBadge = 'New-Lower-Price';
    } else if (badge === 'clearance') {
      ret.priceBadge = 'Clearance';
    }
    return ret.priceBadge;
  },
  getRebates: (hideRebates, hasEcoRebates, itemId, zipCode, large, type) => {
    const showRebates = !hideRebates && hasEcoRebates && zipCode;
    if (showRebates) {
      const useEcoRebates = typeof large === 'boolean' ? large : type === LAYOUT_TYPES.DETAILED;
      return (
        <Rebates
          itemId={itemId}
          large={large}
          optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
          rebateClasses={useEcoRebates ? '' : 'price__ecorebates'}
          zipCode={zipCode}
        />
      );
    }
    return null;
  },
  getWasPrice: (pricingValues) => {
    if (pricingValues) {
      return (
        <div>
          <span>{'Was\xA0'}</span>
          <span className="sui-line-through">{helpers.handlePriceParsing(pricingValues.wasPrice, true)}</span>
        </div>
      );
    }
    return null;
  },
  getWasPriceRange: (wasMinPrice, wasMaxPrice) => {
    if (wasMinPrice && wasMaxPrice) {
      return (
        <span className="u__strike">
          {helpers.handlePriceParsing(wasMinPrice, true)}
          <span> - {helpers.handlePriceParsing(wasMaxPrice, true)}</span>
        </span>
      );
    }
    return null;
  },
  getBulkPrice: (hideBulkPrice, bulkPrice, bulkPriceThresholdQty, uom = null, displayEachUom = false) => {
    if (!hideBulkPrice && bulkPrice) {
      return (
        <div className="u__text--success" data-testid="bulk-price-container">
          {/* eslint-disable max-len */}
          Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span>
          {uom && <span> {uom && helpers.parseUom(uom, displayEachUom)}</span>}
        </div>
      );
    }
    return null;
  },
  getLimitPerOrder: (hideLimitPerOrder, quantityLimit, limitClass) => {
    if (!hideLimitPerOrder && !!quantityLimit) {
      return <div className={limitClass}>{`Limit ${quantityLimit} per order`}</div>;
    }
    return null;
  },
  getSavingsText: (isInline, hideSavingsText, save, showDollarOff = true, savePriceClass = '') => {
    const Element = isInline ? 'span' : 'div';
    if (!hideSavingsText && save) {
      return (
        <Element>{` Save${!showDollarOff ? ` ${save.percentageOff}%` : ''}`}
          {showDollarOff ? (<span> {helpers.handlePriceParsing(save.dollarOff, true)}{` (${save.percentageOff}%)`}</span>)
            : ''}
        </Element>
      );
    }
    return null;
  },
  getUSDollarWithCommas: (price) => {
    const USDollar = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return USDollar.format(price);
  },

  // legacy of using large === true for detailed and large === false for simple
  // replaced with type of 'detailed', 'simple', or 'minimal'
  // type default value is ''
  // large default value is true
  getLayout: (type, large, flooringPickerToggleOn, showProjectPrice) => {
    let layout;

    if (type.length) layout = type;
    else if (large) layout = LAYOUT_TYPES.DETAILED;
    else layout = LAYOUT_TYPES.SIMPLE;

    if (showProjectPrice && flooringPickerToggleOn && layout !== LAYOUT_TYPES.MINIMAL) {
      layout = LAYOUT_TYPES.PROJECT;
    }

    return layout;
  },

  getAlignment: (channel, layout) => {
    return channel !== 'mobile' && layout === LAYOUT_TYPES.MINIMAL ? 'end' : 'start';
  },

  getSavingCenterPromoMessage: (savingsCenterPromos) => {

    let savingCenterPromoMessage = null;

    const hasSavingsCenterPromosBulk = /Bulk Savings/i.test(savingsCenterPromos);
    const hasSavingsCenterPromosBmsm = /Buy More Save More/i.test(savingsCenterPromos);
    const hasSavingsCenterPromosBogo = /Buy One Get One/i.test(savingsCenterPromos);
    const hasSavingsCenterPromosBundle = /Bundle Savings/i.test(savingsCenterPromos);

    if (hasSavingsCenterPromosBogo) {
      savingCenterPromoMessage = 'Save on Select Items With Purchase';
    } else if (hasSavingsCenterPromosBmsm) {
      savingCenterPromoMessage = 'Buy More, Save More';
    } else if (hasSavingsCenterPromosBulk) {
      savingCenterPromoMessage = 'Bulk Savings';
    } else if (hasSavingsCenterPromosBundle) {
      savingCenterPromoMessage = 'Bundle Savings';
    }

    return savingCenterPromoMessage;
  },

  getLocalStoreName: (productData) => {
    let storeName = ' ';

    if (productData) {
      const fulfillmentOption = productData?.fulfillment?.fulfillmentOptions?.find((option) => option?.type === 'pickup');
      if (fulfillmentOption) {
        storeName = fulfillmentOption?.services?.[0]?.locations?.[0]?.storeName;
      }
    }
    return storeName;
  },

  setClearanceAnalytics: (eventType, opts) => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger(`clearanceProduct.${eventType}`, opts);
    }
  }
};

export default helpers;
