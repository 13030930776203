import React from 'react';
import {
  bool, number, shape, string, object
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { TileHead } from '../../TileHead';
import { getDefaultStoreHours, showBopisDate } from '../../../../helper/timeZoneUtils';
import { IDS } from '../../../../../automationTestIds';

const BopisNotFulfillableOOS = ({
  isCartTiles, location, pickupStoreHours
}) => {
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }

  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };

  return (
    <div className="fulfillment-tile">
      <TileHead title="At Your Store" automationId={IDS.LABEL.atYourStoreLabel} />
      <div className="fulfillment__sub-title fulfillment__primary_color">
        <div
          className="u__bold pickup-timeline"
          data-testid="pickupTimeline"
          data-automation-id={IDS.ETA.atYourStoreETA}
        >
          {showBopisDate(pickupStoreHoursWithTZ, true)}
        </div>
        <div>
          <span>{`Visit ${location?.storeName} for availability`}</span>
        </div>
      </div>
    </div>
  );

};
BopisNotFulfillableOOS.propTypes = {
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  location: shape({
    storeName: string,
    distance: number
  }).isRequired
};
BopisNotFulfillableOOS.defaultProps = {
  isCartTiles: false,
  pickupStoreHours: null
};

export { BopisNotFulfillableOOS };
