export const MAJOR_APPLIANCE = 'MAJOR_APPLIANCE';
export const UNAVAILABLE = 'UNAVAILABLE';
export const OOS_ETA_UNAVAILABLE = 'OOS_ETA_UNAVAILABLE';
export const OOS_ALT_MODEL = 'OOS_ALT_MODEL';
export const BACK_ORDERED = 'BACK_ORDERED';
export const AVAILABLE = 'AVAILABLE';
export const ERROR = 'ERROR';
export const DELIVERY_ZIP = 'DELIVERY_ZIP';

export const DELIVERY_ZIP_HEADER = 'Enter Delivery ZIP Code';
export const DELIVERY_ZIP_BODY = 'Enter your ZIP Code to update your delivery options for your local area.';
export const DELIVERY_ZIP_INPUT = 'Delivery ZIP Code';
