/**
 * This hook monitors the selected fulfillment method. When the user changes it, this hook fired a
 * life cycle event bus event to tell other components.
 *
 * At the time of writing this, only Buybox is listening to this event.
 *
 * This hook is important for configurable products. The reason is because when the user clicks add
 * to cart, product configurator intercepts that, disabled the buybox, executes rules, creates a
 * configuration, and then finishes with add to cart. During this process, a disabled version of
 * fulfillment tiles is shown. This is a separate component. This actually removed the fulfillment
 * tiles component. So when it is re-enabled, it uses the default props which may include a
 * different fulfillment method than what the user had selected. If BOSS was the default, and the
 * user selects delivery, then clicks add to cart, without this component the fulfillment would
 * change back to BOSS.
 */
const useSelectedFulfillmentHasChanged = () => {
  const pushNewFulfillmentMethod = ({ selectedFulfillmentMethod }) => {
    if (!selectedFulfillmentMethod || selectedFulfillmentMethod.trim().length === 0) {
      return;
    }

    LIFE_CYCLE_EVENT_BUS.trigger('fulfillment.selectedFulfillment', selectedFulfillmentMethod);
  };

  return {
    pushNewFulfillmentMethod
  };
};

export default useSelectedFulfillmentHasChanged;
