const STH = 'ShipToHome';
const BOPIS = 'BOPIS';
const BOSS = 'ShipToStore';
const BODFS = 'DeliverFromStore';
const APPLIANCE = 'DirectDelivery';
const NOT_AVAILABLE = ['UAVL'];
const AVL = 'AVL';

const getAsNumber = (num = '0') => {
  if (num) {
    return parseInt(num, 10);
  }
  return 0;
};

const isFallbackMode = (ffm) => {
  return !getAsNumber(ffm.availableQuantity) && ffm.inventoryStatus === AVL;
};

const getStoreNameRemoveStoreNumber = (storeNameAndNumber = '') => {
  return storeNameAndNumber.substring(0, storeNameAndNumber.indexOf('#') - 1);
};

const getInventory = (ffm) => {
  const availQuantity = getAsNumber(ffm.availableQuantity);
  const isOutOfStock = ffm.isOutOfStock !== undefined ? !!ffm.isOutOfStock
    : availQuantity < 1 && ffm.inventoryStatus !== AVL;
  return {
    isOutOfStock: (ffm.selected ? false : isOutOfStock),
    isInStock: (ffm.selected ? true : availQuantity > 0),
    isLimitedQuantity: (ffm.selected ? false : ffm.inventoryStatus === 'LQTY'),
    isUnavailable: (ffm.selected ? false : NOT_AVAILABLE.includes(ffm.inventoryStatus)),
    quantity: availQuantity
  };
};

const getLocation = (ffm, isAnchor) => {
  return {
    curbsidePickupFlag: ffm.curbsidePickupEligible,
    isBuyInStoreCheckNearBy: false,
    distance: ffm.distance,
    inventory: getInventory(ffm),
    isAnchor,
    locationId: ffm.pickUpStore,
    state: ffm.state,
    storeName: getStoreNameRemoveStoreNumber(ffm.pickUpStoreAddress),
    storePhone: null,
    type: ffm.fulfillmentMethod === BOPIS ? 'store' : 'online'
  };
};

const getPickupService = (ffm, isBlindsItem) => {
  const pickupService = {
    isBlindsItem,
    deliveryCharge: null,
    freeDeliveryThreshold: null,
    hasFreeShipping: ffm?.freeShipping || false,
    totalCharge: null,
    selected: ffm?.selected || false,
    locations: [],
    type: ffm.fulfillmentMethod === BOPIS ? 'bopis' : 'boss',
    nearbyStores: ffm?.nearbyStoreModel || null,
    storeHours: ffm?.storeHoursModel || null,
    deliveryDates: {
      startDate: ffm?.estStartDeliveryDate,
      endDate: ffm?.estEndDeliveryDate
    },
    fallbackMode: isFallbackMode(ffm)
  };
  pickupService.locations.push(getLocation(ffm, true));
  // for store nearby model
  if (ffm.nearbyStoreModel) {
    pickupService.locations.push(getLocation(ffm.nearbyStoreModel, false));
  }
  return pickupService;
};

const getDeliveryService = (ffm) => {
  let shipCharge;
  if (ffm.fulfillmentMethod === BODFS) {
    shipCharge = ffm.estDeliveryCharge || ffm.totalDeliveryCharge;
  } else {
    shipCharge = ffm.totalShippingCharge;
  }
  return {
    selected: ffm?.selected || false,
    deliveryTimeline: ffm?.deliveryDateAvailability?.toLowerCase() || null,
    deliveryDates: {
      startDate: ffm?.estStartDeliveryDate,
      endDate: ffm?.estEndDeliveryDate || ffm?.estStartDeliveryDate
    },
    deliveryCharge: shipCharge ? Number(shipCharge) : null,
    hasFreeShipping: ffm.freeShipping,
    freeDeliveryThreshold: ffm?.freeShippingIfThresholdMet ? 45 : null,
    // dynamic ETA
    dynamicEta: {
      hours: ffm?.timeLeftHrs,
      minutes: ffm?.timeLeftMins
    },
    locations: [{
      inventory: getInventory(ffm),
      type: ffm.fulfillmentMethod === BODFS ? 'store' : 'online'
    }],
    type: ffm.fulfillmentMethod === BODFS ? 'express delivery' : 'sth',
    totalCharge: shipCharge ? Number(shipCharge) : null,
    maxCartPriceContributor: ffm.maxCartPriceContributor,
    consolidatedDeliveryCharge: ffm.consolidatedDeliveryCharge,
    fallbackMode: isFallbackMode(ffm)
  };
};

const getApplianceDeliveryData = (ffm, item) => {
  const availableStatus = {
    AVL: 'AVAILABLE',
    BACK_ORDERED: 'BACK_ORDERED'
  };
  return {
    DeliveryAvailabilityResponse: {
      deliveryAvailability: {
        zipCode: ffm?.deliveryZipCode,
        primaryStrNbr: item?.applianceDeliveryStore,
        availability: [{
          itemId: item.itemId,
          modelNbr: item.modelNumber,
          status: availableStatus[ffm.inventoryStatus]
        }],
        earliestAvailabilityDate: item?.expectedArrival,
        hasFreeShipping: !item?.applianceDeliveryCharge,
        lineItemId: item?.lineItemId,
        applianceCountInCart: item?.applianceCountInCart
      }
    }
  };
};

export const transformModel = (cartItemModel) => {
  try {
    let model = {
      lineItemId: cartItemModel.lineItemId,
      anchorStoreStatus: true,
      anchorStoreStatusType: 'ACTIVE',
      backorderedShipDate: null,
      bossExcludedShipStates: null,
      excludedShipStates: null,
      seasonStatusEligible: null,
      inStoreAssemblyEligible: cartItemModel?.freeAssemblyEligible || false,
      bodfsAssemblyEligible: cartItemModel?.bodfsAssemblyEligible || false,
      fulfillmentOptions: [{
        type: 'pickup',
        services: []
      },
      {
        type: 'delivery',
        services: []
      }],
      onlineStoreStatus: true,
      onlineStoreStatusType: 'ACTIVE',
      bossExcludedShipState: null,
      sthExcludedShipState: null
    };
    let fulfillable = true;
    let ffmBuyInStore = false;
    let applianceDirectData = null;
    const pickupSvc = model.fulfillmentOptions[0].services;
    const deliverySvc = model.fulfillmentOptions[1].services;
    const itemPrice = cartItemModel?.specialOfferPrice || cartItemModel?.unitPrice || 0;
    const isBlindsItem = !!cartItemModel.configurationId;
    (cartItemModel.fulfillmentModel || []).forEach((ffm) => {
      if (ffm) {
        // for backordered item
        if (ffm.fulfillmentMethod === BOSS || ffm.fulfillmentMethod === STH) {
          model.backordered = ffm.inventoryStatus === 'BO';
        }
        if (ffm.fulfillmentMethod === BOPIS || ffm.fulfillmentMethod === BOSS) {
          pickupSvc.push(getPickupService(ffm, isBlindsItem));
          // In Store Purchase Only
          if (ffm.buyInStore) {
            ffmBuyInStore = true;
          }
        } else if (ffm.fulfillmentMethod === STH || ffm.fulfillmentMethod === BODFS) {
          deliverySvc.push(getDeliveryService(ffm));
        } else if (ffm.fulfillmentMethod === APPLIANCE) {
          applianceDirectData = getApplianceDeliveryData(ffm, cartItemModel);
        }
      } else {
        console.error('Unhandled fulfillment method', ffm.fulfillmentMethod, ffm);
      }
    });
    // for pickup service
    if (pickupSvc.length > 0) {
      model.fulfillmentOptions[0].fulfillable = (ffmBuyInStore ? !ffmBuyInStore : fulfillable);
    }
    // for delivery service
    if (deliverySvc.length > 0) {
      model.fulfillmentOptions[1].fulfillable = fulfillable;
    }
    if (pickupSvc.length === 0 && deliverySvc.length === 0) {
      model.fulfillmentOptions = null;
    }
    return {
      directData: {
        fulfillment: model,
        pricing: { value: itemPrice }
      },
      applianceDirectData
    };
  } catch (err) {
    console.error('fulfillmentCartAdapter: transformModel: ', err);
  }
  return { directData: null, applianceDirectData: null };
};
