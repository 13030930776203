import React, { useState } from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf,
  object
} from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { InfoFilled } from '@one-thd/sui-icons';
import { InventoryWarning } from './InventoryWarning';
import { BossDominantTooltip } from './BossDominantTooltip';
import { FULFILLMENT_METHODS } from '../../../constants';
import { BossDrawer } from './BossDominantDrawers/BossDrawer';
import { isMobile, isDesktop } from '../../../helper/utils';

const DefaultBOPISDetails = ({
  fulfillment,
  itemId,
  originalConfigId,
  isShipToHomeEligible,
  quantity,
  bossFulfillment,
  channel,
  pricing,
  bopisMinimumThreshold,
  bopisMinimumThresholdStores,
  isCartTiles,
  addOnsAssemblyAndMore
}) => {
  const [bossDrawerOpen, setBossDrawerOpen] = useState(false);
  const localStoreLocation = (fulfillment?.locations || []).find((location) => location.isAnchor);
  const isBopisThresholdStore = bopisMinimumThresholdStores?.split(',').indexOf(localStoreLocation?.locationId) !== -1;
  const showMinimumThresholdMessage = (quantity * pricing?.value) < bopisMinimumThreshold;
  const [tooltipInstance, setInstance] = useState(null);
  const onNearByStoreClick = () => {
    if (tooltipInstance?.hide) {
      tooltipInstance.hide();
    }
  };
  return localStoreLocation?.inventory?.isInStock && (
    <div>
      <div>
        {
          isCartTiles && fulfillment?.isBossDominant && (
            <span className="sui-flex">
              <div className="sui-pr-1 sui-pt-1">
                <InfoFilled
                  size="small"
                  style={{ color: '#3e7697' }}
                />
              </div>
              <span>
                {'Need more? Just enter the quantity you need and we will ship it to '}
                <span className="sui-font-bold">
                  {localStoreLocation?.storeName}
                </span>
                {' for FREE Pickup.'}
              </span>
            </span>
          )
        }
        {!isCartTiles && bossFulfillment && isDesktop(channel) && (
          <div>
            <span>{'Need to pickup more? '}</span>
            <Tooltip
              channel={channel}
              closeButton
              placement="bottom"
              onShow={(instance) => {
                setInstance(instance);
              }}
              content={(
                <BossDominantTooltip
                  isShipToHomeEligible={isShipToHomeEligible}
                  itemId={itemId}
                  originalConfigId={originalConfigId}
                  onClick={onNearByStoreClick}
                  storeName={localStoreLocation?.storeName}
                  addOnsAssemblyAndMore={addOnsAssemblyAndMore}
                />
              )}
              interactive
              skidding={-45}
              maxWidth={360}
            >
              <span
                className="delivery__tooltip-text"
                role="button"
                tabIndex={0}
              >
                {' We can ship to your store!'}
              </span>
            </Tooltip>
          </div>
        )}
        {!isCartTiles && bossFulfillment && isMobile(channel)
        && (
          <div>
            <span>{'Need to pickup more? '}</span>
            <span
              className="u__default-link"
              role="button"
              tabIndex={0}
              onClick={() => {
                setBossDrawerOpen(!bossDrawerOpen);
              }}
            >
              {' We can ship to your store!'}
            </span>
            <BossDrawer
              isQuickViewOpen={bossDrawerOpen}
              setIsQuickViewOpen={setBossDrawerOpen}
              storeName={localStoreLocation?.storeName}
              itemId={itemId}
              originalConfigId={originalConfigId}
              addOnsAssemblyAndMore={addOnsAssemblyAndMore}
            />
          </div>
        )}
      </div>
      {showMinimumThresholdMessage && isBopisThresholdStore && (
        <div className="alert-inline alert-inline--warning inventory--warning-tiles">
          <span>There&apos;s a ${bopisMinimumThreshold} minimum purchase for pickup.</span>
        </div>
      )}
      {!isCartTiles && !!localStoreLocation?.inventory?.quantity && localStoreLocation?.inventory?.quantity > 0
      && localStoreLocation?.inventory?.quantity < quantity && (
        <InventoryWarning method={FULFILLMENT_METHODS.BOPIS} location={localStoreLocation} />
      )}
    </div>
  );
};

DefaultBOPISDetails.propTypes = {
  itemId: string.isRequired,
  isShipToHomeEligible: bool,
  quantity: number,
  originalConfigId: string,
  channel: string,
  bossFulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  pricing: shape({
    value: number
  }).isRequired,
  bopisMinimumThreshold: number,
  bopisMinimumThresholdStores: string,
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: object
};

DefaultBOPISDetails.defaultProps = {
  isShipToHomeEligible: false,
  quantity: 1,
  originalConfigId: null,
  bossFulfillment: null,
  channel: 'desktop',
  bopisMinimumThreshold: null,
  bopisMinimumThresholdStores: null,
  isCartTiles: false,
  addOnsAssemblyAndMore: null
};

export { DefaultBOPISDetails };
