import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DeliveryOptions } from '@thd-olt-component-react/delivery-options';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { DetaCountDownTimer } from './DetaCountDownTimer';
import { BackorderedAlert } from '../../alerts/BackorderedAlert';
import { InventoryWarning } from '../store/InventoryWarning';
import { FULFILLMENT_METHODS } from '../../../constants';
import {
  formatDate,
  getDeliveryOptionsText,
  isMycartPage,
  doesCustomerQtyExceedInventory,
  showTodayOrTomorrow
} from '../../../helper/utils';
import './defaultShippingDetails.scss';

const DefaultShippingDetails = ({
  itemId,
  deliveryStartDate,
  deliveryTimeline,
  locations,
  dynamicEtaMinutes,
  dynamicEtaHours,
  backordered,
  storeId,
  zipCode,
  quantity,
  maxInventoryAvail,
  shippingData,
  isCartTiles = false,
  fallbackMode = false,
  isSpecialOrder = false,
  isConfigurableBlinds = false,
  disableTimer = false
}) => {
  const [showCountdownTime, changeCountdownTimeStatus] = useState(true);
  const removeTimer = () => changeCountdownTimeStatus(false);
  const startDate = formatDate(deliveryStartDate, true);
  const sthLocation = (locations || []).find((location) => location?.type === 'online');
  const inventoryQuantity = maxInventoryAvail;
  const deliveryOptionsText = getDeliveryOptionsText(shippingData);
  const { isLocal, isConsumerApp } = useContext(ExperienceContext);
  const showDetaTimer = ((!isConsumerApp && isLocal) || isMycartPage()) && !disableTimer;
  const customerQtyExceedInventory = doesCustomerQtyExceedInventory(maxInventoryAvail, quantity);

  if (isConfigurableBlinds || isSpecialOrder) {
    return null;
  }

  return (
    <>
      {(!isCartTiles && !fallbackMode && zipCode && (
        <div className="delivery-options__wrapper">
          <span className="delivery-options__description-text">{deliveryOptionsText}</span>
          <div className="delivery-options__link">
            <DeliveryOptions
              itemId={itemId}
              storeId={storeId}
              zipCode={zipCode}
              quantity={quantity}
              useDrawer
              showTodayOrTomorrow={showTodayOrTomorrow}
            >
              Delivery Details
            </DeliveryOptions>
          </div>
        </div>
      ))}
      {backordered && !fallbackMode && <BackorderedAlert />}
      {!fallbackMode && showDetaTimer && (
        <div>
          <DetaCountDownTimer
            dynamicEtaMinutes={dynamicEtaMinutes}
            dynamicEtaHours={dynamicEtaHours}
            showCountdownTime={showCountdownTime}
            inventoryQuantity={inventoryQuantity}
            removeTimer={removeTimer}
            deliveryStartDate={deliveryStartDate}
            isLongDayName={false}
            deliveryTimeline={deliveryTimeline}
            startDate={startDate}
            zipCode={zipCode}
          />
        </div>
      )}
      {!backordered && !isCartTiles && customerQtyExceedInventory && !fallbackMode && (
        <InventoryWarning
          method={FULFILLMENT_METHODS.STH}
          location={sthLocation}
          maxInventoryAvail={maxInventoryAvail}
        />
      )}
    </>
  );
};

DefaultShippingDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  backordered: PropTypes.bool,
  deliveryStartDate: PropTypes.string,
  deliveryTimeline: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    isAnchor: PropTypes.bool,
    inventory: PropTypes.shape({
      quantity: PropTypes.number,
      isInStock: PropTypes.bool,
      isLimitedQuantity: PropTypes.bool,
      isBackordered: PropTypes.bool
    })
  })),
  dynamicEtaMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dynamicEtaHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shippingServiceModel: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    freeShippingThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasFreeShipping: PropTypes.bool,
    dynamicETA: PropTypes.shape({
      totalMinutes: PropTypes.string,
      totalHours: PropTypes.string,
      hasCountdownTimer: PropTypes.bool
    })
  }),
  quantity: PropTypes.number,
  zipCode: PropTypes.string,
  storeId: PropTypes.string,
  maxInventoryAvail: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  shippingData: PropTypes.objectOf(PropTypes.any),
  isCartTiles: PropTypes.bool,
  fallbackMode: PropTypes.bool,
  isSpecialOrder: PropTypes.bool,
  isConfigurableBlinds: PropTypes.bool,
  disableTimer: PropTypes.bool
};

DefaultShippingDetails.defaultProps = {
  quantity: 1,
  shippingServiceModel: null,
  backordered: false,
  deliveryStartDate: null,
  deliveryTimeline: null,
  locations: [{
    isAnchor: false,
    inventory: {
      quantity: null,
      isInStock: false,
      isLimitedQuantity: null,
      isBackordered: false
    }
  }],
  dynamicEtaMinutes: null,
  dynamicEtaHours: null,
  zipCode: null,
  storeId: null,
  maxInventoryAvail: null,
  shippingData: {},
  isCartTiles: false,
  fallbackMode: false,
  isSpecialOrder: false,
  isConfigurableBlinds: false,
  disableTimer: false
};

export { DefaultShippingDetails };
