import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType,
  params,
  alias,
  client,
  extend
} from '@thd-nucleus/data-sources';
import {
  dataModel as deliveryDataModel,
  DeliveryOptions
} from '@thd-olt-component-react/delivery-options';

const Service = shapeType({
  deliveryTimeline: stringType(),
  deliveryDates: shapeType({
    startDate: stringType(),
    endDate: stringType()
  }),
  deliveryCharge: stringType(),
  dynamicEta: shapeType({
    hours: stringType(),
    minutes: stringType()
  }),
  hasFreeShipping: boolType(),
  freeDeliveryThreshold: numberType({ float: true }),
  locations: arrayOfType(
    shapeType({
      curbsidePickupFlag: boolType(),
      isBuyInStoreCheckNearBy: boolType(),
      distance: numberType({ float: true }),
      inventory: shapeType({
        isOutOfStock: boolType(),
        isInStock: boolType(),
        isLimitedQuantity: boolType(),
        isUnavailable: boolType(),
        quantity: numberType(),
        maxAllowedBopisQty: numberType(),
        minAllowedBopisQty: numberType()
      }),
      storeTimeZone: stringType(),
      isAnchor: boolType(),
      locationId: stringType(),
      state: stringType(),
      storeName: stringType(),
      storePhone: stringType(),
      type: stringType()
    })
  ),
  type: stringType(),
  optimalFulfillment: boolType(),
  totalCharge: numberType({ float: true }),
  hasSameDayCarDelivery: boolType(),
  shipFromFastestLocation: boolType()
});

export const dataModel = extend(
  {
    clientOnlyProduct: alias('product')
      .params({
        itemId: stringType().isRequired()
      })
      .shape({
        itemId: stringType(),
        dataSources: stringType(),
        identifiers: shapeType({
          productType: stringType()
        }),
        info: shapeType({
          classNumber: stringType(),
          hasSubscription: boolType(),
          isLiveGoodsProduct: boolType(),
          productDepartment: stringType(),
          globalCustomConfigurator: shapeType({
            customExperience: stringType()
          })
        }),
        pricing: params({ storeId: stringType() }).shape({
          original: numberType({ float: true}),
          value: numberType({ float: true }),
          promotion: shapeType({
            promotionTag: stringType()
          }),
          clearance: shapeType({
            value: numberType({ float: true }),
            dollarOff: numberType({ float: true }),
            percentageOff: numberType({ float: true }),
            unitsClearancePrice: numberType({ float: true })
          })
        }),
        availabilityType: shapeType({
          buyable: boolType(),
          discontinued: boolType(),
          status: boolType(),
          type: stringType()
        }),
        fulfillment: client(
          params({ storeId: stringType(), zipCode: stringType(), quantity: numberType() }).shape({
            anchorStoreStatus: boolType(),
            anchorStoreStatusType: stringType(),
            backordered: boolType(),
            backorderedShipDate: stringType(),
            bossExcludedShipStates: stringType(),
            sthExcludedShipState: stringType(),
            bossExcludedShipState: stringType(),
            excludedShipStates: stringType(),
            seasonStatusEligible: boolType(),
            fulfillmentOptions: arrayOfType(
              shapeType({
                type: stringType(),
                fulfillable: boolType(),
                services: arrayOfType(Service)
              })
            ),
            onlineStoreStatus: boolType(),
            onlineStoreStatusType: stringType(),
            fallbackMode: boolType()
          })
        )
      }),
    checkAvailability: alias('checkAvailability')
      .params({
        itemIds: arrayOfType(stringType().isRequired()).isRequired(),
        zipCode: stringType().isRequired(),
        localStoreId: stringType().isRequired()
      })
      .shape({
        fulfillmentOptions: arrayOfType(shapeType({
          fulfillable: boolType(),
          services: arrayOfType(
            shapeType({
              type: stringType(),
              isDefault: boolType(),
              earliestAvailabilityDate: stringType(),
              locations: arrayOfType(
                shapeType({
                  locationId: stringType()
                })
              ),
              deliveryAvailabilityDetail: arrayOfType(shapeType({
                status: stringType(),
                itemId: stringType()
              }))
            })
          )
        }))
      }),
    shipping: deliveryDataModel.shipping
  },
  DeliveryOptions
);