import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../fulfillmentTiles.scss';
import { setErrorEvents } from '../../../analytics';

export const BackorderedAlert = ({ isMajorAppliance = false }) => {
  useEffect(() => {
    setErrorEvents({ message:
      `This product is currently backordered.${!isMajorAppliance ? ' We won\'t charge you until it ships.' : ''}` });
  }, []);

  return (
    <div className="alert-inline alert-inline--warning inventory--warning-tiles">
      <div>
        <span>This product is currently</span>
        <span className="u__bold">&nbsp;backordered.</span>
        {!isMajorAppliance && <span>&nbsp;{'We won\'t charge you until it ships.'}</span>}
      </div>
    </div>
  );
};

BackorderedAlert.propTypes = {
  isMajorAppliance: PropTypes.bool
};

BackorderedAlert.defaultProps = {
  isMajorAppliance: false
};