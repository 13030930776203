import React from 'react';
import {
  string,
  shape,
  number
} from 'prop-types';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const LimitedStockByStore = ({ location }) => {
  return (
    <div className="fulfillment-tile">
      <TileHead title="Limited Stock" automationId={IDS.LABEL.limitedStockLabel} />
      <div>
        <span>{`Visit ${location?.storeName} for availability`}</span>
      </div>
    </div>
  );
};

LimitedStockByStore.propTypes = {
  location: shape({
    storeName: string,
    distance: number
  }).isRequired
};

LimitedStockByStore.defaultProps = {};

export { LimitedStockByStore };
