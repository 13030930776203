import React from 'react';
import PropTypes from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import classNames from 'classnames';
import { isFunction } from '../../../components/helper/utils';
import { DELIVERY_TEMPLATES } from '../../templates/templatesConstant';
import {
  productShape
} from '../../helpers/pod-fulfillment-utils';

export const EmptyShippingMessage = ({ isGCC, customRender, product }) => {
  const isFfmPodHeightEnabled = useConfigService('fs:isFfmPodHeightEnabled') || false;
  const shippingCss = classNames(
    { 'sui-h-10': isFfmPodHeightEnabled }
  );
  const defaultElements = () => {
    if (isGCC) {
      return <div data-testid="ShippingEmptyGCC" className={shippingCss} data-component="EmptyShippingMessageGCC" />;
    }
    return <div data-testid="ShippingEmpty" className={shippingCss} data-component="EmptyShippingMessage" />;
  };

  if (isFunction(customRender)) {
    return customRender(
      { deliveryTemplate: DELIVERY_TEMPLATES.EMPTY_SHIPPING_MESSAGE,
        icon: null,
        values: { isGCC, product },
        defaultElements });
  }

  return (
    defaultElements()
  );
};

EmptyShippingMessage.propTypes = {
  isGCC: PropTypes.bool,
  customRender: PropTypes.func,
  product: PropTypes.shape({ productShape }).isRequired
};

EmptyShippingMessage.defaultProps = {
  isGCC: false,
  customRender: null
};
