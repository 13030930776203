import { useEffect, useCallback, useState } from 'react';
import { FOREIGN_LIFE_CYCLE_EVENTS } from '../components/constants';

export const useAssemblyListeners = ({ isBodfsGrillsAssemblyEnabled, isBodfsGrillsAssemblyEligible }) => {
  const [isBODFSAssemblySelected, setBODFSAssemblySelected] = useState(false);

  const bodfsAssemblyChange = useCallback(({ output = {} }) => {
    const targetFulfillmentType = output?.nextFulfillment;

    const isBODFS = targetFulfillmentType === 'DeliverFromStore';
    setBODFSAssemblySelected(output.isOptedIn && isBODFS);
  }, []);

  const initializeListeners = useCallback(() => {
    LIFE_CYCLE_EVENT_BUS.on(
      FOREIGN_LIFE_CYCLE_EVENTS.productAddOns.bodfsAssemblyChange,
      bodfsAssemblyChange
    );
  }, []);

  const clearListeners = useCallback(() => {
    LIFE_CYCLE_EVENT_BUS.off(FOREIGN_LIFE_CYCLE_EVENTS.productAddOns.bodfsAssemblyChange);

  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isBodfsGrillsAssemblyEligible && (isBodfsGrillsAssemblyEnabled === 1 || isBodfsGrillsAssemblyEnabled === '1')) {
      initializeListeners();
    }
    return clearListeners;
  }, [initializeListeners, clearListeners, isBodfsGrillsAssemblyEnabled, isBodfsGrillsAssemblyEligible]);

  return { isBODFSAssemblySelected };
};