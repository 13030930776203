import React, { useEffect, useState } from 'react';
import {
  bool,
  string,
  shape,
  number,
  oneOfType
} from 'prop-types';
import classNames from 'classnames';
import { Badge } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import PriceFormat from './PriceFormat';
import InstantRebates from './InstantRebates';
import helpers, { PRICE_TYPES } from '../../helpers/price-helper';
import '../../styles/price-detailed.style.scss';
import { LAYOUT_TYPES, triggerNewRelicEvent } from '../../utils/utils';
import { SignInLink } from './subcomponents/signInLink/SignInLink';


export const PriceDetailed = ({
  channel,
  disableRangePricing,
  displayEachUom,
  hideBadge,
  hideBulkPrice,
  hideLimitPerOrder,
  hideRebates,
  hideSavingsText,
  omitDetails,
  skuModel,
  zipCode,
  showStartingAt,
  disableOuterSpacing,
  isClearance,
  useNewMapPolicy,
}) => {

  const { pricing } = skuModel;
  const { isCustomerIdentified = false } = useThdCustomer() || {};

  useEffect(() => {
    if (pricing?.itemId) {
      triggerNewRelicEvent({
        actionName: isCustomerIdentified ? 'PriceDisplayedAfterAuth' : 'MapPriceDisplayed',
        itemId: pricing?.itemId,
        layoutType: 'DetailedView',
        subExperience: 'PriceDetailed',
        customerStatus: isCustomerIdentified ? 'authenticated' : 'unauthenticated',
        pricing,
        isCustomerIdentified // Pass the authentication status
      });
    }
  }, [isCustomerIdentified, pricing?.itemId, useNewMapPolicy]);

  const uomWrapper = classNames('sui-text-base sui-font-bold sui-ml-2');
  const outerPriceWrapper = classNames('sui-flex sui-flex-col');
  const innerPriceWrapper = classNames('sui-flex sui-flex-row sui-leading-none');
  const responsivePriceWrapper = classNames('sui-flex sui-flex-col sm:sui-flex-row sui-leading-none');
  const strikethroughWrapper = classNames('sui-font-regular sui-text-base sui-flex sui-flex-col sui-mt-1 sm:sui-mt-0 sm:sui-ml-2');

  // BRIO Skus
  const handleBRIOSku = () => (
    <div className="brio__wrapper">
      <div className="brio__quote-message-wrapper">
        <span className="brio__quote-message">Get a quote during a FREE in-home consultation</span>
      </div>
      <div className="brio__how-to-buy">How to Buy</div>
      <div className="brio__how-to-buy-details">
        {`During your free, in-home consultation one of our professional contractors will assess your home, discuss
          product and extended service options that best fit your needs and budget. Our expert will review financing
          options and provide a total project cost estimate. Your quote will include product, labor, and any
          additional parts or extended service options. Product availability may be limited.`}
      </div>
      <span className="brio__icon schedule_furnish_install_logo">Schedule Furnish Install Logo</span>
    </div>
  );

  // HIDE_PRICE/LIVE_GOODS
  const handleHidePrice = () => <div className="price-detailed__contact-message">Contact your local store for price</div>;

  // Limit per order with strike through
  const handleLimitPerOrder = ({
    price, pricingValues, priceBadge, uom, save, hasEcoRebates
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    if (!!pricing.quantityLimit) {
      return (
        <div className={outerPriceWrapper}>
          {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'sui-text-base sui-mb-2')}
          <div className={responsivePriceWrapper}>
            <div className={innerPriceWrapper}>
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                isSmallBadge={false}
                isSmallSize={false}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
              />
              {uom && <span className="sui-font-bold sui-ml-2">{helpers.parseUom(uom, displayEachUom)} </span>}
            </div>
            <div className="sui-flex sui-flex-col sui-text-base sui-font-regular sui-mt-1 sm:sui-mt-0 sm:sui-ml-2 sui-mb-2">
              <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
              <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
            </div>

          </div>
          <div className="sui-mb-2">
            {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
          </div>
        </div>
      );
    }
    return '';
  }

  // Unit and case price: regular,
  // with strike through, with bulk price, with bulk price and strike through
  const handleUnitAndCasePrice = ({
    price, alternatePrice, alternateUom, uom, pricingValues,
    save, bulkPrice, bulkPriceThresholdQty, priceBadge,
    unitPricingValues, hasEcoRebates
  }) => {
    const priceTemplate = helpers.handlePriceParsing(price, false);
    const coveragePriceTemplate = helpers.handlePriceParsing(price, true);
    const altPriceTemplate = helpers.handlePriceParsing(alternatePrice, false);
    const altPrice = alternatePrice.toString();
    const priceObj = helpers.parsePriceValue(price);
    const altUom = (alternateUom && alternateUom !== 'each') ? alternateUom : uom;
    const { unitWasPrice, unitNowPrice } = pricing;
    const pricePerUnit = unitWasPrice || unitNowPrice;
    const symbolClass = classNames(
      'sui-text-3xl sui-font-display sui-leading-none'
    );
    const dollarClass = classNames(
      'sui-font-display sui-leading-none sui-text-9xl sui--translate-y-[0.5rem] sui-px-[2px]',
    );
    const centsClass = classNames(
      'sui-font-display sui-leading-none sui-text-3xl'
    );
    const [altPriceDollars, altPriceCents] = altPrice.split('.');

    if (pricePerUnit) {
      if (uom !== 'sq. ft.') {
        return (
          <div id="unit-case-price-includes">
            {pricing.unitsPerCase > 1 && (
              <div className="sui-font-regular sui-text-base sui-leading-none sui-mb-2">{pricing.unitOfMeasureCoverage}</div>
            )}
            {!bulkPrice &&
              <div className={responsivePriceWrapper}>
                <div className={innerPriceWrapper}>
                  {priceBadge &&
                    <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                  <div className={innerPriceWrapper}>
                    <span className={symbolClass}>$</span>
                    <span className={dollarClass}>{altPriceDollars}</span>
                    <span className="sui-sr-only">.</span>
                    <span className={centsClass}>{altPriceCents}</span>
                  </div>
                  <div className={outerPriceWrapper}>
                    <span className={uomWrapper}> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                    <span className="sui-ml-2">({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})</span>
                  </div>

                </div>
                <div className={strikethroughWrapper}>
                  <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
                  <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
                </div>
              </div>

            }
            {(bulkPrice && !hideBulkPrice) &&
              <div className="sui-flex sui-flex-col sui-ml-1 sui-mt-2">
                <div className={responsivePriceWrapper}>
                  <div className={innerPriceWrapper}>

                    {priceBadge && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                    <div className={innerPriceWrapper}>
                      <span className={symbolClass}>$</span>
                      <span className={dollarClass}>{altPriceDollars}</span>
                      <span className={centsClass}>{altPriceCents}</span>
                    </div>
                    {!pricingValues && (
                      <div>
                        <span className={uomWrapper}> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                        {pricing.unitsPerCase > 1 && (
                          <div className="sui-mt-1 sui-ml-2">
                            <span>({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})</span>
                          </div>
                        )}
                      </div>
                    )}
                    {pricingValues &&
                      <div className="sui-flex sui-flex-col sui-ml-2">
                        <span className="sui-font-bold">{alternateUom &&
                          helpers.parseUom(alternateUom, displayEachUom)}
                        </span>
                        <span>({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})</span>
                      </div>
                    }

                  </div>
                  <div className={strikethroughWrapper}>
                    <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
                    <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
                  </div>
                </div>
                <span>{helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}</span>
              </div>
            }
            {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
          </div>
        );
      } else if (uom === 'sq. ft.') {
        return (
          <>
            <div id="unit-case-price" className={outerPriceWrapper}>
              <span className="sui-font-regular sui-text-base sui-leading-none">{pricing.unitOfMeasureCoverage}</span>
              <div className="sui-flex sui-flex-col sm:sui-flex-row sui-mt-2 sui-leading-none">
                {price && (
                  <div className={innerPriceWrapper}>
                    {(priceBadge && !hideBadge) && <div className={`sui-mr-2 value-price-badge__${priceBadge}`} />}
                    <div className={innerPriceWrapper}>
                      <span className={symbolClass}>$</span>
                      <span className={dollarClass}>{priceObj.dollars}</span>
                      <span className="sui-sr-only">.</span>
                      <span className={centsClass}>{priceObj.cents}</span>
                      <div className="sui-ml-2">
                        <span className="sui-font-bold sui-text-base sui-leading-none"> {uom && helpers.parseUom(uom, displayEachUom)} </span>
                        <div>
                          <span>({altPriceTemplate} {alternateUom && helpers.parseUom(alternateUom, displayEachUom)})</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={strikethroughWrapper}>
                  <span className="sui-text-subtle">{helpers.getWasPrice(unitPricingValues)}</span>
                  <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save, false)}</span>
                </div>
              </div>
            </div>
            <span className="sui-mt-2">{helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}</span>
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
          </>
        );
      }
    }
    return (
      <div id="unit-price">
        <PriceFormat
          price={altPrice}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        {!pricingValues &&
          <div className="price-detailed__was-price">
            <span className="priceFormat__unit-alignment">{altUom && helpers.parseUom(altUom, displayEachUom)} </span>
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
          </div>
        }
        {pricingValues &&
          <div className="price-detailed__was-price">
            <span className="priceFormat__unit-alignment">{altUom && helpers.parseUom(altUom, displayEachUom)} </span>
            {helpers.getWasPrice(pricingValues)}
            {helpers.getSavingsText(true, hideSavingsText, save)}
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
          </div>
        }
        {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'price-detailed__qty-limit')}
        {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
      </div>
    );
  }

  // Was price
  const handleWasPrice = ({
    pricingValues, save, priceBadge, uom
  }) => {
    return (
      <div id="was-price" className={responsivePriceWrapper}>
        <div className={innerPriceWrapper}>
          <PriceFormat
            price={pricingValues.nowPrice}
            priceBadge={priceBadge}
            isSmallBadge={false}
            isSmallSize={false}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          {uom && <span className="sui-ml-2 sui-font-bold sui-text-base sui-leading-none">{helpers.parseUom(uom, displayEachUom)} </span>}
        </div>
        <div className="sui-flex sui-flex-col sui-font-regular sui-text-base sui-leading-none sm:sui-ml-2 sui-mt-1 sui-mb-2 sm:sui-mt-0">
          <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
          <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
        </div>
      </div>
    );
  }

  // Starting At price
  const handleStartingAt = ({
    pricingValues, save, price, priceBadge, uom
  }) => {
    const startingAtClass = classNames({
      'price--hidden': !showStartingAt
    });
    return (
      <div id="start-at" className={startingAtClass}>
        <div>Starting at</div>
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          isSmallBadge={false}
          isSmallSize={false}
          disableOuterSpacing={disableOuterSpacing}
        />
        {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
        <div>
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(false, hideSavingsText, save)}
        </div>
      </div>
    )
  };

  // Map price
  const handleMapPrice = ({
    mapPrice, pricingValues, uom, priceBadge, pricingMessage, mapPolicy, save, hasStrikeThrough
  }) => {
    const tooltipContent = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';
    const hasLowerPrice = /See Low Price in Cart|See Lower Price in Cart|see final price in cart/.test(pricingMessage);

    hasStrikeThrough = useNewMapPolicy ? hasStrikeThrough : true;

    let priceToDisplay = '';
    if (mapPrice) {
      priceToDisplay = mapPrice.wasPrice;
      hasStrikeThrough = mapPrice.hasStrikeThrough;
    } else if (pricingValues) {
      priceToDisplay = useNewMapPolicy && pricingValues.nowPrice ? pricingValues.nowPrice : pricingValues.wasPrice;
    }
    const tooltipId = `price-tooltip-${pricing.itemId}`;
    const priceMsgClass = classNames('u__default-link');
    const lowPriceType = channel.toLowerCase() === 'mobile' ? 'lower-price-small' : 'lower-price';
    const savingsExists = save?.dollarOff && save?.percentageOff;
    const GetToolTip = () => {
      return <Tooltip
        channel={channel}
        closeButton
        content={tooltipContent}
        maxWidth={300}
        placement="bottom"
      >
        {/* eslint-disable-next-line */}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            helpers.triggerAnalytics('map-link');
          }}
          id={tooltipId}
          className={priceMsgClass}
        >
          {pricingMessage}
        </a>
      </Tooltip>
    }
    let mapPolicyMessage;
    if (useNewMapPolicy && mapPolicy === 'showSpecialPriceIfAuth' && !isCustomerIdentified) {
      mapPolicyMessage = <SignInLink layoutType='detailed' itemId={pricing?.itemId} />
    } else if (mapPolicy !== 'showSpecialPriceIfAuth') {
      mapPolicyMessage = <GetToolTip />
    }
    return (
      <div className={outerPriceWrapper}>
        {(mapPrice || pricingValues) && (
          <div className={innerPriceWrapper}>
            {(hasLowerPrice && !hideBadge && !priceBadge && priceToDisplay) &&
              <Badge type={lowPriceType} className="price-detailed__lower-price" />}
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
              disableOuterSpacing={disableOuterSpacing}
              hasStrikeThrough={hasStrikeThrough}
            />
            {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)} </span>}
            {(useNewMapPolicy && savingsExists) && (
              <div
                className="sui-flex sui-flex-col sui-font-regular sui-text-base sui-leading-none sui-ml-2 sui-mt-1 sui-mb-2 sm:sui-mt-0">
                <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
                <span className="sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
              </div>
            )}
          </div>
        )}
        {mapPolicyMessage}
      </div>
    );
  }

  // Bulk price
  const handleBulkPrice = ({
    alternateUom, price, pricingValues, bulkPrice, save, uom,
    bulkPriceThresholdQty, priceBadge
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="bulk-price" className={outerPriceWrapper}>
        <div className={innerPriceWrapper}>
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            isSmallBadge={false}
            isSmallSize={false}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)} </span>}
          <div className="sui-flex sui-flex-col sui-text-base sui-ml-2 sui-mt-1 sm:sui-mt-0">
            <span className="sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
            <span className="sui-text-success">{helpers.getSavingsText(true, hideSavingsText, save)}</span>
          </div>
        </div>
        {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
      </div>
    );
  }

  // Instant Rebate
  const handleInstantRebate = ({
    price, pricingValues, priceBadge, uom
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <>
        <div id="instant-rebate" className={responsivePriceWrapper}>
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            isSmallBadge={false}
            isSmallSize={false}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />

          <div className="sui-flex sui-flex-col sui-ml-2 sui-mt-2 sm:sui-mt-0">
            {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
            <span className="sui-font-regular sui-text-base sui-leading-none sui-color-subtle">{helpers.getWasPrice(pricingValues)}</span>
          </div>

        </div>
        {!hideRebates && (
          <InstantRebates
            storePromotion={pricing.storePromotion}
            price={pricing}
            channel={channel}
            hasDetails
          />
        )}
      </>
    );
  }

  // Eco Rebate
  const handleEcoRebates = ({
    price, priceBadge, uom, pricingValues, save,
    mapPrice, pricingMessage, bulkPrice, bulkPriceThresholdQty, mapPolicy
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="eco-rebate-price" className="sui-mb-1">
        {(bulkPrice && !hideBulkPrice) && handleBulkPrice({
          price, priceBadge, uom, pricingValues, save, bulkPrice, bulkPriceThresholdQty
        })}
        {(mapPrice || pricingMessage) && handleMapPrice({
          mapPrice, price, priceBadge, uom, pricingMessage, mapPolicy, save
        })}
        {(!mapPrice && !pricingMessage && !bulkPrice) && (
          <div className={responsivePriceWrapper}>
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
              disableOuterSpacing={disableOuterSpacing}
            />
            <div className="sui-flex sui-flex-col sm:sui-ml-2 sui-mt-1 sui-mb-2 sm:sui-mt-0">
              {uom &&
                <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
              }
              <span className="sui-font-regular sui-text-base sui-leading-none sui-text-subtle">{helpers.getWasPrice(pricingValues)}</span>
              <span className="sui-font-regular sui-text-base sui-leading-none sui-text-success">{helpers.getSavingsText(false, hideSavingsText, save)}</span>
            </div>
          </div>
        )}
        {helpers.getRebates(hideRebates, true, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
      </div>
    );
  }

  // No details
  const handleNoDetailsPrice = ({
    price, uom, pricingValues, save, priceBadge
  }) => (
    <div id="no-details-price">
      {price && (
        <>
          <PriceFormat
            price={price}
            priceBadge={priceBadge}
            isSmallBadge={false}
            isSmallSize={false}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <div className="price-detailed__was-price">
            {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
            {helpers.getWasPrice(pricingValues)}
            {helpers.getSavingsText(false, hideSavingsText, save)}
          </div>
        </>
      )}
    </div>
  );

  // Standard price
  const handleStandardPrice = ({
    price, priceBadge, uom
  }) => (
    <div id="standard-price" className={innerPriceWrapper}>
      <PriceFormat
        price={price}
        priceBadge={priceBadge}
        isSmallBadge={false}
        isSmallSize={false}
        hideBadge={hideBadge}
        disableOuterSpacing={disableOuterSpacing}
      />
      {uom && <span className={uomWrapper}>{helpers.parseUom(uom, displayEachUom)}</span>}
    </div>
  );

  // Range Price
  const handleRangePrice = ({
    minPrice, maxPrice, wasMinPrice, wasMaxPrice, save
  }) => (
    <div id="range-price">
      <div>
        <PriceFormat
          price={minPrice}
          isSmallSize={false}
          isSmallBadge={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <span className="u__bold price__range"> - </span>
        <PriceFormat
          price={maxPrice}
          isSmallSize={false}
          isSmallBadge={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
      </div>
      <div>
        {helpers.getWasPriceRange(wasMinPrice, wasMaxPrice)}
        {helpers.getSavingsText(true, hideSavingsText, save, false)}
      </div>
    </div>
  );

  const renderByType = ({ _price, type }) => {
    switch (type) {
      case PRICE_TYPES.STANDARD:
        return handleStandardPrice(_price);
      case PRICE_TYPES.STRIKE_THROUGH:
        return handleWasPrice(_price);
      case PRICE_TYPES.STARTING_AT:
        return handleStartingAt(_price);
      case PRICE_TYPES.MAP_PRICE:
        return handleMapPrice(_price);
      case PRICE_TYPES.ECO_REBATE:
        return handleEcoRebates(_price);
      case PRICE_TYPES.INSTANT_REBATE:
        return handleInstantRebate(_price);
      case PRICE_TYPES.BULK_PRICE:
        return handleBulkPrice(_price);
      case PRICE_TYPES.UNIT_AND_CASE:
        return handleUnitAndCasePrice(_price);
      case PRICE_TYPES.LIMIT_PER_ORDER:
        return handleLimitPerOrder(_price);
      case PRICE_TYPES.BRIO:
        return handleBRIOSku();
      case PRICE_TYPES.LIVE_GOODS:
        return handleHidePrice();
      case PRICE_TYPES.NO_DETAILS:
        return handleNoDetailsPrice(_price);
      case PRICE_TYPES.RANGE_PRICE:
        return handleRangePrice(_price);
      default: return null;
    }
  };

  const wrapperClass = classNames('sui-flex sui-flex-col', {
    'sui-p-0': disableOuterSpacing
  });

  return (
    <div className={wrapperClass}>
      {renderByType(helpers.getPricingObject({
        skuModel, displayEachUom, omitDetails, basic: false, disableRangePricing, useNewMapPolicy
      }))}
    </div>
  );
}

PriceDetailed.propTypes = {
  channel: string.isRequired,
  disableRangePricing: bool,
  displayEachUom: bool,
  hideBadge: bool,
  hideBulkPrice: bool,
  hideLimitPerOrder: bool,
  hideRebates: bool,
  hideSavingsText: bool,
  omitDetails: bool,
  skuModel: shape({
    pricing: shape({
      uom: string,
      unitsPerCase: number,
      wasPrice: oneOfType([number, string]),
      nowPrice: oneOfType([number, string]),
      specialBuyPrice: oneOfType([number, string]),
      dollarOff: oneOfType([number, string]),
      percentageOff: oneOfType([number, string]),
      specialBuySavings: oneOfType([number, string]),
      specialBuyDollarOff: oneOfType([number, string]),
      alternatePriceDisplay: bool,
      quantityLimit: oneOfType([number, string]),
      itemId: oneOfType([number, string]),
      unitWasPrice: oneOfType([number, string]),
      unitNowPrice: oneOfType([number, string]),
      storePromotion: shape(),
      unitOfMeasureCoverage: string,
      mapPolicy: bool,
    }).isRequired
  }).isRequired,
  zipCode: string,
  showStartingAt: bool,
  disableOuterSpacing: bool
};

PriceDetailed.defaultProps = {
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hideRebates: false,
  hideSavingsText: false,
  omitDetails: false,
  zipCode: null,
  showStartingAt: false,
  disableOuterSpacing: false,
  isClearance: false,
  useNewMapPolicy: false
};

