import React from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { InventoryWarning } from './InventoryWarning';
import { FULFILLMENT_METHODS } from '../../../constants';
import { BackorderedAlert } from '../../alerts/BackorderedAlert';

const DefaultBoSSDetails = ({
  fulfillment,
  backordered,
  quantity,
  maxInventoryAvail,
  isCartTiles
}) => {
  const bossLocation = (fulfillment?.locations || []).find((location) => location.type === 'online');

  return (
    <>
      {backordered && <BackorderedAlert />}
      {!backordered && !!bossLocation?.inventory?.quantity && bossLocation?.inventory?.quantity > 0
      && bossLocation?.inventory?.quantity < quantity && !isCartTiles && (
        <InventoryWarning
          method={FULFILLMENT_METHODS.STORE}
          location={bossLocation}
          maxInventoryAvail={maxInventoryAvail}
        />
      )}
    </>
  );
};

DefaultBoSSDetails.propTypes = {
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  backordered: bool,
  quantity: number,
  maxInventoryAvail: number,
  isCartTiles: bool
};

DefaultBoSSDetails.defaultProps = {
  backordered: false,
  quantity: 1,
  maxInventoryAvail: 0,
  isCartTiles: false
};

export { DefaultBoSSDetails };
