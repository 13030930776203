import React from 'react';
import { TileHead } from '../TileHead';
import { IDS } from '../../../../automationTestIds';

const BOSSOutOfStock = () => {
  return (
    <div className="fulfillment-tile">
      <TileHead title="Ship to Store" automationId={IDS.LABEL.stsLabel} />
      <div className="fulfillment__sub-title">Out of stock</div>
    </div>
  );
};

BOSSOutOfStock.propTypes = {
};

BOSSOutOfStock.defaultProps = {
};

export { BOSSOutOfStock };
