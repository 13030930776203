import THDStorageUtils, { LOCAL_STORAGE } from '@thd-olt-global/thd-storage-utils';

const shouldClearStorage = (calculateObj) => {
  const { areas } = calculateObj;
  let shouldClearStorageBool = true;

  const depthArrHasNonDefaultValues = areas.some((el) => !['3', ''].includes(el.depth));
  const areaArrHasValues = areas.some((el) => el.squareFootage !== '');
  const calcValuesExist = ((areas.some((el) => el.name !== '' || el.length !== '' || el.width !== '')
    || depthArrHasNonDefaultValues
    || areaArrHasValues));

  if (calcValuesExist) shouldClearStorageBool = false;

  return shouldClearStorageBool;
};

const formatStorageKey = (l1Label) => {
  if (!l1Label) {
    return null;
  }
  const storageKey = 'CALC-' + l1Label.toUpperCase();
  return storageKey;
};

const getStorageObject = (storageKey) => {
  try {
    const storageValue = THDStorageUtils.get(LOCAL_STORAGE, { key: storageKey });
    const storageObject = storageValue !== 'undefined' ? JSON.parse(storageValue) : null;
    /*
      temp migration for calcByArea on areas and squareFootage turning into a value rather than just an object
      should be removed February 2025 - this gives 1 year since old implementation, so that localStorage objects
      with the old version have time to expire
     */
    if (typeof storageObject?.calcByArea !== 'undefined') {
      storageObject.areas = storageObject.areas.map((area) => {
        return {
          ...area,
          calcByArea: storageObject.calcByArea,
          squareFootage: typeof area.squareFootage === 'object' ? area.squareFootage.value : area.squareFootage
        };
      });
      storageObject.calcByArea = undefined;
    }

    return storageObject;
  } catch (error) {
    return null;
  }
};

export const removeStorageObject = (storageKey) => {
  THDStorageUtils.remove(LOCAL_STORAGE, { key: storageKey });
};

export const saveStorageObject = (storageKey, calculate) => {
  const {
    areas, addAdditionalCoverage, stairs
  } = calculate;
  const storageObj = getStorageObject(storageKey);

  if (!storageObj && shouldClearStorage(calculate)) return;
  if (storageObj && shouldClearStorage(calculate)) {
    removeStorageObject(storageKey);
  } else {
    THDStorageUtils.set(LOCAL_STORAGE, {
      key: storageKey,
      value: JSON.stringify({
        areas,
        addAdditionalCoverage,
        stairs
      })
    });
  }

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('calculator.localStorageUpdated', { storageKey, storageObj });
};

export const getCalculatorLocalStorage = ({ l1Label }) => {
  const storageKey = formatStorageKey(l1Label);
  let storageObj = null;
  if (storageKey) {
    storageObj = getStorageObject(storageKey);
  }
  return {
    storageKey,
    storageObj
  };
};