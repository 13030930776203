import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '../typography/Typography';
import useFormController from '../hooks/useFormController';
import formControllerState from '../utils/formControllerState';

/**
 * Component used to provide adornments for `Textfield` components.
 *
 * Adornments can be:
 * - An icon from the
 *  [Icon Library](https://brand.homedepot.com/site/knapsack-nucleus/latest/pattern/icons?subPageId=DESIGN).
 * - An `IconButton` for actions.
 * - Standard text to add a prefix or a suffix.
 *
 * Related components: [FormController](#formcontroller), [TextField](#textfield), [Input](#input),
 * [IconButton](#iconbutton)
 *
 * Usage:
 *
 * ```jsx
 * import { InputAdornment } from '@one-thd/sui-atomic-components';
 * ```
 */
const InputAdornment = React.forwardRef((props, ref) => {

  const { children, position, ...other } = props;

  const childType = typeof children === 'string' ? 'typography' : children.type.displayName;

  const formControl = useFormController();
  const fcs = formControllerState({
    props,
    formControl,
    states: ['disabled']
  });

  let adornment;
  switch (childType) {
    case 'typography':
      adornment = (
        <Typography component="span" color={fcs.disabled ? 'inactive' : 'subtle'}>
          {children}
        </Typography>
      );
      break;
    case 'IconButton': {
      const button = React.Children.only(children);
      adornment = React.cloneElement(button, {
        ref,
        iconSize: 'small',
        buttonSize: 'normal',
        disabled: fcs.disabled
      });
      break;
    }
    default:
      adornment = children;
  }

  const classes = classNames('sui-flex', {
    'sui-mr-2': position === 'start',
    'sui-ml-2': position === 'end'
  });

  return (
    <span className={classes} ref={ref} {...other}>
      {adornment}
    </span>
  );
});

InputAdornment.displayName = 'InputAdornment';

InputAdornment.propTypes = {
  /**
   * The content of the component, normally an `Icon`, `IconButton` or string.
   */
  children: PropTypes.node,
  /**
   * The position this adornment should appear.
   */
  position: PropTypes.oneOf(['end', 'start']).isRequired
};

export { InputAdornment };