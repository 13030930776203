import React from 'react';
import {
  bool,
  string,
  shape,
  number
} from 'prop-types';
import { useDomPath } from '@thd-nucleus/experience-context';
import { FULFILLMENT_METHODS } from '../../../constants';
import { track } from '../../../../analytics';

const OnlineInventoryWarning = ({ location, maxInventoryAvail }) => {
  const { isInStock, quantity } = location?.inventory;
  const displayQty = maxInventoryAvail > quantity ? maxInventoryAvail : quantity;
  const [path, ref] = useDomPath();
  const message = 'The requested quantity is not available. Please reduce the quantity.';
  return (
    <div className="alert-inline alert-inline--warning inventory--warning-tiles">
      <div>
        {isInStock && displayQty && (
          <>
            {track('postPageAdvisoryEvent', { path, message })}
            <span ref={ref}>Only </span>
            <span className="u__bold">{displayQty}</span>
            <span> in stock. Please reduce the quantity or change your fulfillment to proceed.</span>
          </>
        )}
      </div>
    </div>
  );
};

OnlineInventoryWarning.propTypes = {
  location: shape({
    isAnchor: bool,
    storeName: string,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired,
  maxInventoryAvail: number
};

OnlineInventoryWarning.defaultProps = {
  maxInventoryAvail: 0
};

const StoreInventoryWarning = ({ location }) => {
  const isAnchor = location?.isAnchor;
  const { isInStock, quantity } = location?.inventory;
  const prefix = isAnchor ? 'Your store has only' : 'Your nearby store has only';
  const [path, ref] = useDomPath();
  const message = 'The requested quantity is not available. Please reduce the quantity.';
  return (
    <div className="alert-inline alert-inline--warning inventory--warning-tiles">
      <div>
        {isInStock && (
          <>
            {track('postPageAdvisoryEvent', { path, message })}
            <span ref={ref}>
              {prefix}
            </span>
            <span className="u__bold"> {quantity}</span>
            <span> in stock. </span>
          </>
        )}
        <span>
          Please reduce the quantity, check nearby pickup stores, or change your fulfillment to proceed.
        </span>
      </div>
    </div>
  );
};

StoreInventoryWarning.propTypes = {
  location: shape({
    isAnchor: bool,
    storeName: string,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired
};

const BODFSInventoryWarning = ({ location, maxInventoryAvail }) => {
  const { isInStock, quantity } = location?.inventory;
  const displayQty = maxInventoryAvail > quantity ? maxInventoryAvail : quantity;
  const [path, ref] = useDomPath();
  const message = 'The requested quantity is not available. Please reduce the quantity.';
  return (
    <div className="alert-inline alert-inline--warning inventory--warning-tiles">
      <div>
        {isInStock && displayQty && (
          <>
            {track('postPageAdvisoryEvent', { path, message })}
            <span ref={ref}>Only </span>
            <span className="u__bold">{displayQty}</span>
            <span> in stock. Please reduce the quantity or change your fulfillment to proceed.</span>
          </>
        )}
      </div>
    </div>
  );
};

BODFSInventoryWarning.propTypes = {
  location: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired,
  maxInventoryAvail: number
};

BODFSInventoryWarning.defaultProps = {
  maxInventoryAvail: 0
};

const InventoryWarning = ({ method, location, maxInventoryAvail }) => {
  switch (method) {
  case FULFILLMENT_METHODS.BOPIS:
    return (<StoreInventoryWarning location={location} />);
  case FULFILLMENT_METHODS.STORE:
  case FULFILLMENT_METHODS.STH:
    return (
      <OnlineInventoryWarning
        location={location}
        maxInventoryAvail={maxInventoryAvail}
      />
    );
  case FULFILLMENT_METHODS.BODFS:
    return (
      <BODFSInventoryWarning
        location={location}
        maxInventoryAvail={maxInventoryAvail}
      />
    );
  default:
    return null;
  }
};

InventoryWarning.propTypes = {
  method: string,
  location: shape({
    isAnchor: bool,
    storeName: string,
    inventory: shape({
      quantity: number,
      isInStock: bool
    })
  }).isRequired,
  maxInventoryAvail: number
};

InventoryWarning.defaultProps = {
  method: null,
  maxInventoryAvail: 0
};

export { InventoryWarning };
