/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  FULFILLMENT_METHODS,
  SCHEDULE_DELIV,
  APPLIANCE_SHIPPING_MIN_MET,
  APPLIANCE_SHIPPING_MIN_NOT_MET,
  SCHEDULE_DELIV_LATER,
  SIX_HOUR_CAR_SCHEDULE_DELIV,
  BODFS_CAR_SCHEDULE_DELIV
} from '../constants';
import { getSTHServiceLevelCount } from '../helper/utils';
import { isUnavailableOrOutOfStock, meetsApplianceDeliveryThreshold } from '../../pod/helpers/pod-fulfillment-utils';
import {
  DeliveryMessageUnderTilePlaceholder
} from './Placeholder/DeliveryMessageUnderTilePlaceholder';

export const DeliveryMessageUnderTile = React.memo(({
  shippingData, bodfsFulfillment, currDeliveryTileFfm, isShippingLoading,
  isBuyBoxLite, isMajorAppliance, applianceDeliveryData, isCartTiles, pricing, isBODFSCartMessagingOn = false,
  enableApplianceDeliveryCharge, enableFreeDeliveryForExchange, isExchangeCustomer, bodfsCarDeliveryPriceValue
}) => {
  const isBODFSCartMessaging = useConfigService('isBODFSCartMessagingOn') || isBODFSCartMessagingOn;
  const bodfsCarDeliveryPrice = useConfigService('bodfsCarDeliveryPrice') || bodfsCarDeliveryPriceValue;
  const isCartBODFSMessagingEnabled = isCartTiles && isBODFSCartMessaging;
  const isBodfsAvailable = !isUnavailableOrOutOfStock(bodfsFulfillment);
  const bodfsDeliveryTimeline = bodfsFulfillment?.deliveryTimeline?.toLowerCase();
  const noDeliveryAvailable = (!shippingData && !isBodfsAvailable && !isMajorAppliance);
  const sthServiceLevelCount = getSTHServiceLevelCount(shippingData);

  const isDeliverySthOnly = isCartBODFSMessagingEnabled ? false : (!isBodfsAvailable && sthServiceLevelCount > 0);
  const priceForFreeShip = meetsApplianceDeliveryThreshold(pricing);
  const isApplianceDataLoading = (isMajorAppliance && !applianceDeliveryData);
  const isMinimumFreeShipPrice = isMajorAppliance && priceForFreeShip;
  const hasSameDayCarDelivery = bodfsFulfillment?.hasSameDayCarDelivery || false;
  const isMajorApplianceDeliveryType = currDeliveryTileFfm === FULFILLMENT_METHODS.APPLIANCE;

  // Feature switch Overrides
  const applianceFlatChargeApplies = enableApplianceDeliveryCharge && !isExchangeCustomer;
  const forceFreeShippingForExchange = isExchangeCustomer && enableFreeDeliveryForExchange;

  if (!shippingData && !isBuyBoxLite && (isShippingLoading || isApplianceDataLoading)) {
    return <DeliveryMessageUnderTilePlaceholder />;
  }

  if (noDeliveryAvailable || isDeliverySthOnly || isBuyBoxLite || (isCartTiles && !isBODFSCartMessaging)) {
    return null;
  }

  const isSameBODFSDeliveryPriceFS = !!(bodfsFulfillment?.deliveryCharge
    && bodfsFulfillment?.deliveryCharge?.toString() === bodfsCarDeliveryPrice?.toString());

  const getDeliveryMessageContent = () => {
    // $1.99 BODFS message scenario on PIP
    if (isBodfsAvailable && isSameBODFSDeliveryPriceFS && !isCartTiles) {
      return {
        titleText: BODFS_CAR_SCHEDULE_DELIV.TITLE_TEXT1 + bodfsDeliveryTimeline
          + BODFS_CAR_SCHEDULE_DELIV.TITLE_TEXT2 + bodfsFulfillment?.deliveryCharge + '. ',
        bodyText: BODFS_CAR_SCHEDULE_DELIV.BODY_TEXT,
        isTextWrapping: true
      };
    }

    if (isBodfsAvailable && hasSameDayCarDelivery) {
      return {
        titleText: SIX_HOUR_CAR_SCHEDULE_DELIV.TITLE_TEXT + ' ',
        bodyText: SIX_HOUR_CAR_SCHEDULE_DELIV.BODY_TEXT,
        isTextWrapping: true
      };
    }

    // FEATURE SWITCH OVERRIDE
    // APPLIANCE Non Exchage Customer with enableApplianceDeliveryCharge
    if (applianceFlatChargeApplies && isMajorApplianceDeliveryType) {
      return {
        titleText: APPLIANCE_SHIPPING_MIN_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT,
        bodyTextAdditional: !isCartTiles ? APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT_ADDITIONAL : ''
      };
    }

    // FEATURE SWITCH OVERRIDE
    // APPLIANCE Exchange customer with enableFreeDeliveryForExchange
    if (forceFreeShippingForExchange && isMajorApplianceDeliveryType) {
      return {
        titleText: APPLIANCE_SHIPPING_MIN_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT
      };
    }

    // Item is eligible for BODFS and delivery timeline is today/tomorrow
    if (isBodfsAvailable && bodfsDeliveryTimeline && bodfsDeliveryTimeline !== 'later') {
      return {
        titleText: SCHEDULE_DELIV.TITLE_TEXT + bodfsDeliveryTimeline + '.',
        bodyText: SCHEDULE_DELIV.BODY_TEXT,
        isTextWrapping: true
      };
    }

    // Item is eligible for BODFS and delivery timeline is later
    if (isBodfsAvailable && bodfsDeliveryTimeline === 'later') {
      return { titleText: SCHEDULE_DELIV_LATER.TITLE_TEXT, bodyText: SCHEDULE_DELIV_LATER.BODY_TEXT };
    }

    // APPLIANCE + Free shipping minimum met
    if (isMinimumFreeShipPrice && isMajorApplianceDeliveryType) {
      return { titleText: APPLIANCE_SHIPPING_MIN_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_MET.BODY_TEXT };
    }

    // APPLIANCE + Free shipping minimum not met
    if (!isMinimumFreeShipPrice && isMajorApplianceDeliveryType) {
      return {
        freeText: APPLIANCE_SHIPPING_MIN_NOT_MET.FREE_TEXT,
        titleText: APPLIANCE_SHIPPING_MIN_NOT_MET.TITLE_TEXT,
        bodyText: APPLIANCE_SHIPPING_MIN_NOT_MET.BODY_TEXT,
        isTextWrapping: true
      };
    }

    return { titleText: '', bodyText: '' };
  };

  const {
    freeText, titleText, bodyText, bodyTextAdditional = '', isTextWrapping = false
  } = getDeliveryMessageContent();

  if (!titleText || !bodyText) { return null; }

  return (
    <div className={!isCartTiles ? 'deliverymsg-undertile--block' : ''}>
      { !isCartTiles && <div className="deliverymsg-undertile--divider-top" data-testid="deliverymsg-divider-top" /> }
      <div
        className="deliverymsg-undertile--delivery-express-truck-icon
          deliverymsg-undertile--delivery-express-truck-icon--padding"
        data-testid="delivery-express-truck-icon"
      />
      <div className={'deliverymsg-undertile--content' + (isCartTiles ? ' deliverymsg-undertile--cart-content' : '')}>
        {freeText && (
          <span className="u__bold">
            {freeText}{' '}
          </span>
        )}
        <span className="u__bold">{titleText}</span>{' '}
        {isTextWrapping
          ? (<span>{bodyText}</span>)
          : (<div>{bodyText}</div>)}
        {bodyTextAdditional && (
          <div className="u__bold">{bodyTextAdditional}</div>
        )}
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  // This "propsAreEqual" method is used to determine if we re-render the DeliveryMessageUnderTile component,
  // or just pull from React.memo() memory cache.
  const yesReRender = false;
  const noReRender = true;

  if (prevProps?.currDeliveryTileFfm !== nextProps?.currDeliveryTileFfm) {
    return yesReRender;
  }
  // deep comparisons
  if (JSON.stringify(prevProps?.bodfsFulfillment) !== JSON.stringify(nextProps?.bodfsFulfillment)) {
    return yesReRender;
  }
  if (JSON.stringify(prevProps?.shippingData) !== JSON.stringify(nextProps?.shippingData)) {
    return yesReRender;
  }
  if (prevProps?.isShippingLoading !== nextProps?.isShippingLoading) {
    return yesReRender;
  }
  return noReRender;
});

DeliveryMessageUnderTile.propTypes = {
  /*  shippingData is the GraphQL data from shipping query, If STH is not available, this prop be null */
  shippingData: PropTypes.objectOf(PropTypes.any),
  /*  If BODFS ffm exists for product, bodfsFulfillment will be populated  */
  bodfsFulfillment: PropTypes.objectOf(PropTypes.any),
  /*  The current Fulfillment shown on Delivery Tile  */
  currDeliveryTileFfm: PropTypes.oneOf([
    FULFILLMENT_METHODS.STH, FULFILLMENT_METHODS.BODFS, FULFILLMENT_METHODS.APPLIANCE
  ]).isRequired,
  /* is the shipping graphQL data loading */
  isShippingLoading: PropTypes.bool.isRequired,
  isBuyBoxLite: PropTypes.bool,
  isMajorAppliance: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  applianceDeliveryData: PropTypes.object,
  isCartTiles: PropTypes.bool,
  pricing: PropTypes.object,
  isBODFSCartMessagingOn: PropTypes.bool,
  enableApplianceDeliveryCharge: PropTypes.bool,
  enableFreeDeliveryForExchange: PropTypes.bool,
  isExchangeCustomer: PropTypes.bool,
  bodfsCarDeliveryPriceValue: PropTypes.string
};

DeliveryMessageUnderTile.defaultProps = {
  shippingData: null,
  bodfsFulfillment: null,
  isBuyBoxLite: false,
  isMajorAppliance: false,
  applianceDeliveryData: null,
  isCartTiles: false,
  pricing: {value: 0},
  isBODFSCartMessagingOn: false,
  enableApplianceDeliveryCharge: false,
  enableFreeDeliveryForExchange: false,
  isExchangeCustomer: false,
  bodfsCarDeliveryPriceValue: ''
};
