import React from 'react';
import { string } from 'prop-types';

const TileHead = ({ title, icon, automationId = '' }) => {

  return (
    <div className="fulfillment__head" data-automation-id={automationId}>
      <span className="u__bold">{title}</span>
      { icon && (<span className={`fulfillment__icon ${icon}`} />)}
    </div>
  );
};
TileHead.propTypes = {
  title: string,
  icon: string,
  automationId: string
};
TileHead.defaultProps = {
  title: '',
  icon: '',
  automationId: ''
};

export { TileHead };
