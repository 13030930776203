import React from 'react';
import { string, bool } from 'prop-types';
import {
  LIVE_GOODS_IN_SEASON_NEW,
  LIVE_GOODS_OUT_OF_SEASON_NEW
} from '../../constants';
import './liveGoods.scss';

const LiveGoods = ({ storeName, seasonStatusEligible }) => {
  return (
    <div className="fulfillment__unavailable" data-component="FulfillmentLiveGoods">
      <div className="live-goods__wrapper">
        {seasonStatusEligible && (
          <>
            <div className="live-goods__two-tile-header live-goods__in-season">{LIVE_GOODS_IN_SEASON_NEW}</div>
            <div className="live-goods__content">
              <span>Stop by&nbsp;</span>
              <span className="u__bold">{storeName}</span>
              <span>&nbsp;to see this variety and more in person</span>
            </div>
          </>
        )}
        {!seasonStatusEligible && (
          <>
            <div className="live-goods__two-tile-header">{LIVE_GOODS_OUT_OF_SEASON_NEW}</div>
            <div className="live-goods__content">
              <span>Visit&nbsp;</span>
              <span className="u__bold">{storeName}</span>
              <span>&nbsp;next season to find this plant and more</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

LiveGoods.propTypes = {
  storeName: string,
  seasonStatusEligible: bool
};

LiveGoods.defaultProps = {
  storeName: null,
  seasonStatusEligible: false
};

export { LiveGoods };
