import React from 'react';
import {
  string,
  shape,
  number,
  object,
  bool
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { formatDistance } from '../../../helper/utils';
import { getDefaultStoreHours, showBopisDate } from '../../../helper/timeZoneUtils';
import { IDS } from '../../../../automationTestIds';

const InStockNearByStore = ({ nearByStoreLocation, pickupStoreHours, isCartTiles }) => {
  // Need to use the nearbyStoreHours from the API once in place and remove the useStore .
  // Until then we are taking storeHours from the experience-context.
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };
  return (
    <div className="fulfillment-tile">
      <TileHead title="Pickup Nearby" automationId={IDS.LABEL.pickupNearByLabel} />
      <div className="fulfillment__sub-title">
        <div
          className="u__bold pickup-timeline"
          data-testid="pickupTimeline"
          data-automation-id={IDS.ETA.pickupNearByETA}
        >
          {showBopisDate(pickupStoreHoursWithTZ)}
        </div>
        <div className="u__text--success fulfillment-qty-row" data-automation-id={IDS.QTY.pickupNearByQty}>
          {`In stock ${formatDistance(nearByStoreLocation?.distance)} mi away`}
        </div>
      </div>
      <TileFooter className="u__bold">
        <span className="u__text--success">FREE</span>
      </TileFooter>
    </div>
  );
};
InStockNearByStore.propTypes = {
  nearByStoreLocation: shape({
    storeName: string,
    distance: number
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  isCartTiles: bool
};

InStockNearByStore.defaultProps = {
  isCartTiles: false,
  pickupStoreHours: null
};

export { InStockNearByStore };
