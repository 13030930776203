import React from 'react';
import {
  shape,
  string,
  bool,
  func
} from 'prop-types';
import { TrackOutOfStock } from '@thd-olt-component-react/email-signup';

const NotifyMe = (props) => {
  const {
    isMajorAppliance,
    showCheckAvailability,
    setShowCheckAvailability,
    itemId,
    store,
    shipping,
    skipEmailOptIn
  } = props;

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCheckAvailability(!showCheckAvailability);
  };

  const availabilityButton = (
    <button
      type="button"
      className="u__default-link"
      role="link"
      onClick={handleOnClick}
    >
      Change Delivery Zip Code
    </button>
  );

  if (isMajorAppliance) {
    return (
      <div className="fulfillment__unavailable" data-component="FulfillmentNotifyMe">
        <div className="fulfillment__out-of-stock"> Out of Stock</div>
        <div>This product is currently out of stock.</div>
        <span>{availabilityButton}</span>
      </div>
    );
  }

  const noStockAvailable = !shipping.enabled && !store.enabled && shipping.outOfStock;

  return (
    <div className="fulfillment__unavailable" data-component="FulfillmentNotifyMe">
      <div className="fulfillment__out-of-stock"> Out of Stock</div>
      {(!skipEmailOptIn && (shipping?.showNotify || noStockAvailable)) ? (
        <div className="fulfillment__email">
          <TrackOutOfStock itemId={itemId} />
        </div>
      ) : (
        <span>This item is unavailable online and in stores.</span>
      )}
    </div>
  );
};

NotifyMe.propTypes = {
  isMajorAppliance: bool,
  showCheckAvailability: bool.isRequired,
  setShowCheckAvailability: func.isRequired,
  itemId: string,
  store: shape({
    method: string,
    unavailable: bool
  }),
  shipping: shape({
    method: string,
    unavailable: bool
  }),
  skipEmailOptIn: bool
};

NotifyMe.defaultProps = {
  itemId: null,
  store: null,
  shipping: null,
  skipEmailOptIn: false,
  isMajorAppliance: false
};

export { NotifyMe };
